// about data
export const TimelineData = [
    {
        id: 0,
        type: 1,
        date: "2024-03-11",
        place: "Forello Expo Tanjombato",
        title: "Table ronde : Le développement de la filière aérienne à Madagascar",
        summary: "Discussion à bâton rompu entre les opérateurs de la filière aérienne à Madagascar sur les perspectives à court, moyen et long terme dans le développement de l’aérien sur la grande île. Les projets en cours initiés par l’Etat et les opérateurs du secteur seront au cœur des échanges.",
        time: "14h à 16h",
    },
    {
        "id": 1,
        "type": 2,
        "date": "2024-04-12",
        "place": "Forello Expo",
        "title": "Nouvelles perspectives stratégiques pour la douane malagasy",
        "summary": "Conférence débat en panel suivie d'une séance de questions/réponses et de réseautage avec le public sur différents modèles d'entreprises du transport, de la logistique et de la manutention qui ont une approche promouvant la durabilité",
        "time": "9h30 à 11h45",
    },
    {
        "id": 2,
        "type": 3,
        "date": "2024-04-12",
        "place": "Forello Expo",
        "title": "Optimiser ses chances dans la réponse aux appels d'offres publics",
        "summary": "Séance d'échanges et de travail participatif avec les entités entrant en jeu dans la passation des marchés publics et les décaissements pour renforcer la capacité des opérateurs à répondre aux appels d'offre et maximiser leurs chances d'être retenus.",
        "time": "13h30 à 17h30",
    },
    {
        "id": 3,
        "type": 1,
        "date": "2024-03-12",
        "place": "Forello Expo Tanjombato",
        "title": "Le renforcement des capacités comme levier pour les opérateurs du transport, de la logistique et de la manutention",
        "summary": "Conférence débat en panel suivie d'une séance de questions/réponses et de réseautage avec le public sur les possibilités de renforcement pour les opérateurs du transport, de la logistique et de la manutention.",
        "time": "14h à 16h",
    },
    {
        "id": 4,
        "type": 2,
        "date": "2024-03-12",
        "place": "Centre ville",
        "title": "Les solutions pour une protection optimale des produits à travers le conditionnement et l'entreposage",
        "summary": "Causerie sur les solutions de conditionnement et d'entreposage pour les opérateurs dans une ambiance détendue autour d'un cocktail.",
        "time": "18h30 à 20h",
    },
    {
        "id": 5,
        "type": 3,
        "date": "2024-04-13",
        "place": "Forello Expo",
        "title": "Des nouveaux modèles économiques durables pour révolutionner le transport, la logistique et la manutention",
        "summary": "Conférence débat en panel suivie d'une séance de questions/réponses sur le potentiel d'amélioration des impacts sociaux et environnementaux du transport, de la logistique et de la manutention.",
        "time": "9h30 à 11h30",
    },
    {
        "id": 6,
        "type": 1,
        "date": "2024-04-13",
        "place": "Forello Expo",
        "title": "Vers des routes plus sûres pour les citoyens, les entreprises et les marchandises",
        "summary": "Conférence débat en panel suivie d'une séance de questions/réponses sur les projets d'amélioration de la sécurité routière prévus à court et moyen terme par l'Etat et ses partenaires.",
        "time": "14h à 16h",
    }
]