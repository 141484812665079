import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    body {
      margin: 0; line-height: normal;
    }

    input {
      outline : 0;
      border: none;
    }
    
    input:focus {
      outline : 0;
      border: none;
    }

:root {

  .leaflet-container {
    width: 100%;
    height: 100vh;
  }

/* fonts */
--paragraph: Inter;
--font-montserrat: Montserrat;

/* font sizes */
--paragraph-size: 16px;
--font-size-31xl: 50px;
--font-size-11xl: 30px;
--font-size-127xl-3: 146.3px;
--font-size-6xl: 25px;
--font-size-mini: 14px;
--heading-4-size: 33px;
--font-size-xl: 20px;
--font-size-81xl: 100px;
--font-size-lg: 18px;
--font-size-base-2: 16.2px;
--font-size-10xl-7: 29.7px;
--font-size-mini-4: 14.4px;
--font-size-xl-9: 20.9px;
--font-size-sm-1: 13.1px;
--font-size-base-7: 15.7px;

/* Colors */
--color-whitesmoke-100: #f8f4f1;
--green: #9ccd20;
--white: #fff;
--brand: #f4842d;
--third: #ebebeb;
--color-white: #fff;
--primary: #f4842d;
--secondary: #423e3b;
--color-gainsboro-100: #d9d9d9;
--color-gainsboro-200: #d8d8d8;
--color-black: #000;
--color-gray: #092132;
--color-darkorange: #f68122;
--color-steelblue: #0082ab;
--color-darkgray: #959595;
--color-orange-100: #febd1c;

/* Gaps */
--gap-xs: 12px;
--gap-11xl: 30px;
--gap-5xl: 24px;
--gap-18xl: 37px;
--gap-xl: 20px;
--gap-3xs: 10px;
--gap-sm: 14px;
--gap-13xl: 32px;
--gap-10xl: 29px;
--gap-21xl: 40px;
--gap-4xs: 9px;
--gap-lg: 18px;
--gap-6xs-7: 6.7px;
--gap-8xl: 27px;
--gap-5xs: 8px;
--gap-xl-2: 20.2px;
--gap-31xl: 50px;

/* Paddings */
--padding-55xl: 150px;
--padding-31xl: 50px;
--padding-9xs: 4px;
--padding-21xl: 40px;
--padding-mini: 15px;
--padding-3xl: 22px;
--padding-3xs: 10px;
--padding-6xl: 15px;
--padding-11xl: 30px;
--padding-xl: 20px;
--padding-81xl: 100px;
--padding-3xl-7: 22px;
--padding-7xl-3: 26.3px;
--padding-41xl: 60px;

/* Border radiuses */
--br-7xs: 6px;
--br-mini: 7px;
--br-6xs-7: 6.7px;
--br-xl: 20px;
--br-8xs: 5px;

a {
  text-decoration: none;
  color: inherit;
}

@mixin hide-scrollbar {
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none; // IE and Edge
  &::-webkit-scrollbar {
    display: none; // WebKit (Chrome, Safari, etc.)
  }
}

}
`;
