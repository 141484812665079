import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom/cjs/react-router-dom'
// import logoD from '../assets/Images/AK3D.svg'
// import logoL from '../assets/Images/AK3.svg'

const Logo = styled.img`
	position: fixed;
	left: 0;
	top: 1.5rem;
	z-index:21;
`

const LogoComponent = (props) => {
    return (
		<NavLink to={"/"}>
			<Logo 
				src={(props.theme === "dark")?("/logo-fex-2.png"):("logo-fex-2.png")}
				style={{
					height: 50,
					width: "auto"
				}}
			/>
		</NavLink>
    )
}

export default LogoComponent
