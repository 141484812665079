import React, { useState, useEffect } from 'react';
import { Route, Switch, useLocation } from "react-router"
import { ThemeProvider } from "styled-components"
import { lightTheme } from "./components/Themes"
import GlobalStyle from "./globalStyles"
import NavIcons from './subComponents/NavIcons'
import Navbar from './subComponents/Navbar'
import Footer from './components/Footer'


// Components
import Main from './views/Main';
import AboutPage from './views/AboutPage';
import BlogPage from './views/BlogPage';
import Salon from "./views/Salon";
import Contact from "./views/Contact";
import BlogDetail from "./views/BlogDetail"
import Attendee from './views/Attendee';
import Galerie from './views/GaleriePage';
import GalerieDetail from './views/GalerieDetail';
import InDev from './views/InDev';
import Annuaire from "./views/Annuaire"
import DetailAn from './views/DetailAn';
import TimelinePage from './views/TimelinePage';
import TimelineNext from "./views/TimelineNext"
import { AnimatePresence } from "framer-motion";
import LogoComponent from "./subComponents/LogoComponent";
// import SoundBar from "./subComponents/SoundBar";

import ReactGA from 'react-ga';
// Initialize React Ga with your tracking ID
ReactGA.initialize('GTM-N9S3WF2T');


function App() {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width <= 768); // You can adjust the threshold (768px) as needed
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return(
    <>
      <GlobalStyle />
      <ThemeProvider theme={lightTheme}>
        {/* <SoundBar /> */}
        <LogoComponent />
        {isMobile && (
          <div
            style={{
              display: "flex",
              position: "fixed",
              zIndex: 100,
              backgroundColor: "#fffa",
              backdropFilter: "blur(5px)",
              width: "100vw",
              height: "100vh",
              alignItems: "center",
              justifyContent: "center",
              padding: 50
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: 50,
                borderTop: "solid 6px var(--brand)"
              }}
            >
              {/* Your popup content here */}
              <h2 
                style={{
                  textAlign: "left"
                }}
              >
                Meilleure experience avec un écran plus large.
              </h2>
              <ul>
                <li>Ordinateur</li>
                <li>Tablette</li>
              </ul>
              <p>La version pour les petits écrans sera bientôt disponible.</p>
            </div>
          </div>
        )}
        {/* For framer-motion animation on page change! */}
        <AnimatePresence mode='wait'>
          <Switch  location={location} key={location.pathname}>
            <Route exact path="/" component={Main}/>
            <Route path="/about" component={AboutPage}/>
            <Route exact path={`/salon/:salon`} component={Salon}/>
            <Route exact path="/blog" component={BlogPage}/>
            <Route exact path="/contact" component={Contact}/>
            <Route exact path={`/blog/:id`} component={BlogDetail}/>
            <Route exact path="/invitation" component={Attendee}/>
            <Route exact path={`/galerie`} component={Galerie}/>
            <Route exact path={`/galerie/:event/:eventtype`} component={GalerieDetail}/>
            <Route exact path={`/annuaire/:initCategorie`} component={Annuaire}/>
            <Route exact path={`/annuaire/detail/:id`} component={DetailAn}/>
            <Route exact path="/timeline" component={TimelinePage}/>
            <Route exact path={`/timeline/:id`} component={TimelineNext}/>
          </Switch>
        </AnimatePresence>
      </ThemeProvider>
    </>
  )
}

export default App

