import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Navbar from "../subComponents/Navbar";
import { useParams, NavLink } from "react-router-dom/cjs/react-router-dom";
import { SalonData } from '../data/SalonData';
import InDev from "./InDev";
import HorizontalScroll from 'react-scroll-horizontal'
import { motion } from 'framer-motion'
import { getEventFromApi } from '../api/api'

const FrameChild = styled.div`
  width: 363.6px;
  position: absolute;
  margin: 0 !important;
  top: 27.9px;
  left: -7.2px;
  background: linear-gradient(90deg, #0082ab, #22a7f0);
  height: 46.8px;
  z-index: 0;
`;
const Salon1 = styled.div`
  width: 346.5px;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 69.54px;
  font-weight: 900;
  font-family: var(--font-montserrat);
  display: inline-block;
  text-shadow: 0px 4.6px 46.36px rgba(0, 0, 0, 0.5);
  z-index: 1;
`;
const UnSalonToujours = styled.i`
  align-self: stretch;
  position: relative;
  font-weight: 200;
`;
const LoremIpsumDolor = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-base-2);
`;
const UnSalonToujoursEnMouvementParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 18px 0px;
  z-index: 2;
  font-size: var(--font-size-10xl-7);
  color: var(--secondary);
`;
const FrameItem = styled.img`
  width: 67.5px;
  position: relative;
  height: 67.5px;
`

const Btn = styled(NavLink)`
  position: relative;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: solid 2px black;
  cursor: pointer;
  background-color: black;
  color: white;

  &:hover {
    background-color: black;
    color: white;
  }
`;

const BtnBlackBg = styled(NavLink)`
  position: relative;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: solid 2px white;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: black;
  }
`;

const GroupParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0px 27px;
  z-index: 3;
  font-size: 17.1px;
`;
const RectangleParent = styled.div`
  width: 349.2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  gap: 27px 0px;
  z-index: 0;
`;
const FrameInner = styled.img`
  width: 50px;
  position: absolute;
  margin: 0 !important;
  top: -39.5px;
  left: 76px;
  height: 50px;
  z-index: 1;
`;
const FrameParent = styled.div`
  width: 664px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 550px;
  z-index: 0;
`;
const WelcomeSectionChild = styled.img`
  width: 700px;
  // position: absolute;
  margin: 0 !important;
  bottom: 0px;
  left: 684px;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;
const WelcomeSection = styled.div`
  align-self: stretch;
  width: auto;
  background-color: var(--color-third);
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 7;
  font-size: 86.9px;
  color: var(--color-white);
`;
const MotivationEtObjectif = styled.b`
  align-self: stretch;
  position: relative;
`;
const BlankLine = styled.p`
  margin: 0;
`;
const MotivationEtObjectifParent = styled.div`
  width: 452.7px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 18px 0px;
`;
const LeSalonEn = styled.b`
  position: absolute;
  top: 0px;
  left: 0px;
  display: inline-block;
  width: 518px;
`;
const GroupIcon = styled.img`
  width: 103.4px;
  position: relative;
  height: 104.7px;
`;
const TlchargerLeBrochure1 = styled.b`
  position: relative;
`;
const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 6.67px 0px;
`;
const FrameContainer = styled.div`
  position: absolute;
  top: 52.2px;
  left: 0px;
  width: 518px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lg);
  text-align: center;
  font-size: var(--font-size-lg);
`;
const LeSalonEnChiffreParent = styled.div`
  width: 518px;
  position: relative;
  height: 255.4px;
`;
const FrameGroup = styled.div`
  align-self: stretch;
  background-color: var(--color-gainsboro-200);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-81xl);
  gap: 40px 0px;
  z-index: 6;
`;
const LoremIpsumDolor1 = styled.i`
  width: 452.7px;
  position: relative;
  font-size: var(--font-size-10xl-7);
  display: inline-block;
  font-weight: 200;
`;
const LesSecteursReprsents = styled.b`
  width: 452.7px;
  position: relative;
  display: inline-block;
`;
const LoremIpsumDolor2 = styled.div`
  width: 354.6px;
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  flex-shrink: 0;
  padding-top: 10px;
  padding-bottom: 10px;
`;
const LoremIpsumDolorSitAmetCoWrapper = styled.div`
  align-self: stretch;
  border-top: 0.9px solid var(--color-black);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-4xs) 0px;
`;
const FrameParent1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const LoremIpsumDolorSitAmetCoParent = styled.div`
  width: 503px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 40px 0px;
`;
const SalonInner = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-81xl);
  z-index: 5;
`;
const FrameIcon = styled.img`
  width: 362px;
  position: absolute;
  margin: 0 !important;
  top: 137px;
  left: 305px;
  height: 394.1px;
  z-index: 0;
`;
const Vnements = styled.div`
  width: 435px;
  position: absolute;
  margin: 0 !important;
  top: 27px;
  left: -129px;
  font-size: 68.2px;
  letter-spacing: -0.1em;
  line-height: 151.42px;
  text-transform: uppercase;
  font-weight: 800;
  font-family: var(--font-montserrat);
  color: var(--color-steelblue);
  display: inline-block;
  z-index: 1;
`;
const RectangleDiv = styled.div`
  width: 30px;
  position: relative;
  background-color: var(--color-darkorange);
  height: 30px;
`;
const MotivationEtObjectif1 = styled.div`
  align-self: stretch;
  position: relative;
`;
const LoremIpsumDolorSitAmetCoWrapper1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: var(--font-size-lg);
`;
const RectangleGroup = styled.div`
  width: 371px;
  margin: 0 !important;
  position: absolute;
  top: 127px;
  left: 599px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px 0px;
  z-index: 2;
`;
const RectangleContainer = styled.div`
  width: 371px;
  margin: 0 !important;
  position: absolute;
  top: 427px;
  left: 691px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px 0px;
  z-index: 3;
`;
const RectangleParent1 = styled.div`
  width: 372px;
  margin: 0 !important;
  position: absolute;
  top: 474px;
  left: 221px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px 0px;
  z-index: 4;
`;
const EllipseDiv = styled.div`
  width: 125px;
  position: absolute;
  margin: 0 !important;
  top: 272px;
  left: 384px;
  border-radius: 50%;
  background-color: var(--color-steelblue);
  height: 125px;
  z-index: 5;
`;
const FrameParent2 = styled.div`
  align-self: stretch;
  width: 1228px;
  background-color: var(--color-gainsboro-200);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 210px var(--padding-81xl) var(--padding-81xl);
  box-sizing: border-box;
  position: relative;
  gap: 40px 0px;
  min-width: 550px;
  z-index: 4;
  font-size: var(--font-size-11xl);
`;
const LoremIpsumDolorSitAmetCoWrapper2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: var(--font-size-base-2);
`;
const PlanningDesConfrenceParent = styled.div`
  width: 452.7px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 9px 0px;
  z-index: 0;
`;
const Planning = styled.div`
  width: 470.7px;
  position: absolute;
  margin: 0 !important;
  right: 163px;
  bottom: -114.2px;
  font-size: 40px;
  letter-spacing: -0.07em;
  line-height: 205.26px;
  text-transform: uppercase;
  font-weight: 800;
  font-family: var(--font-montserrat);
  color: var(--color-gainsboro-100);
  display: flex;
  align-items: center;
  height: 213.5px;
  flex-shrink: 0;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  z-index: 1;
`;
const RectangleIcon = styled.img`
  width: 495px;
  position: absolute;
  margin: 0 !important;
  bottom: 0px;
  left: 252px;
  height: 458px;
  object-fit: contain;
  z-index: 2;
`;
const FrameParent3 = styled.div`
  align-self: stretch;
  background-color: var(--color-gray);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-81xl);
  position: relative;
  gap: var(--gap-xl);
  z-index: 3;
  color: var(--third);
`;
const ThmatiquesDeConfrenceParent = styled.div`
  width: 452.7px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 9px 0px;
`;
const FrameParent4 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-81xl) var(--padding-81xl) var(--padding-81xl) 150px;
  gap: var(--gap-xl);
  z-index: 2;
`;
const Partenariat = styled.div`
  position: relative;
  letter-spacing: -0.14em;
  line-height: 81.66px;
  text-transform: uppercase;
  font-weight: 800;
`;
const PartenairesInstitutionnels = styled.div`
  width: 433px;
  position: relative;
  line-height: 48px;
  font-weight: 900;
  display: inline-block;
`;
const Ceb40d2700004f23850Icon = styled.img`
  width: auto;
  position: relative;
  height: 70px;
  object-fit: cover;
`;
const FedexLogoPngTransparentIcon = styled.img`
  width: 218px;
  position: relative;
  height: 70px;
  object-fit: cover;
`;
const NikeLogo191024x1024Icon = styled.img`
  width: 133.8px;
  position: relative;
  height: 70px;
  object-fit: cover;
`;
const AudiLogoIcon = styled.img`
  width: 114.4px;
  position: relative;
  height: 70px;
  object-fit: cover;
`;
const Ceb40d2700004f23850Parent = styled.div`
  align-self: stretch;
  // border-bottom: 1px solid var(--color-black);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-11xl) var(--padding-9xs);
  gap: var(--gap-10xl);
  // min-width: 400px;
`;
const PartenairesInstitutionnelsParent = styled.div`
  // position: absolute;
  // top: 0px;
  // left: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 0px var(--padding-31xl);
`;
const SponsorsParent = styled.div`
  // position: absolute;
  // top: 178px;
  // left: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 0px var(--padding-31xl);
`;
const FrameParent5 = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-6xl);
  color: var(--secondary);
`;
const PartnersSection = styled.div`
  align-self: stretch;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 70px;
  gap: 32px;
  z-index: 1;
  font-size: 73.6px;
  color: var(--color-gainsboro-100);
  font-family: var(--font-montserrat);
`;
const PartenairesEntreprisesParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 0px var(--padding-31xl);
`;
const SalonRoot = styled(motion.div)`
  position: relative;
  background-color: var(--third);
  width: 100%;
  height: 100vh;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-8xl);
  color: var(--secondary);
  font-family: var(--paragraph);
  
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none; // IE and Edge
  &::-webkit-scrollbar {
    display: none; // WebKit (Chrome, Safari, etc.)
  }
`;

const container = {

  hidden: {opacity:0},
  show: {
    opacity:1,

    transition:{
      staggerChildren: 0.2,
      duration: 0.5,
    }
  }

}

const Salon = () => {
    let { salon } = useParams();
    const [data, setData] = useState([]);

    useEffect(() => {
        window.scrollTo(0,0)
        getEventFromApi(salon).then((data)=>{
            if(data !== undefined){
                setData(data)
            }
        })
    }, []);

    return (
      <SalonRoot
        variants={container}
        initial='hidden'
        animate='show'
        exit={{
            opacity:0, transition:{duration: 0.5}
        }}
      >
          <Navbar menuColor={"black"} id="Navbar" />
          <HorizontalScroll
            reverseScroll = { true }
          >
            <WelcomeSection>
            <FrameParent>
              <RectangleParent>
                <FrameChild />
                <Salon1>{data.name}</Salon1>
                <UnSalonToujoursEnMouvementParent>
                  <UnSalonToujours>{data.theme}</UnSalonToujours>
                  <LoremIpsumDolor>{data.description}</LoremIpsumDolor>
                </UnSalonToujoursEnMouvementParent>
                <GroupParent>
                  {/* <FrameItem alt="" src="/group-2.svg" /> */}
                  <Btn to="/contact">
                    Participer
                  </Btn>
                </GroupParent>
              </RectangleParent>
              <FrameInner alt="" src="/rectangle-9.svg" />
            </FrameParent>
          </WelcomeSection>
          <WelcomeSectionChild alt="" src={(data.image)?(data.image):("/rectangle-2@2x.jpg")} />
          <FrameGroup>
            <MotivationEtObjectifParent>
              <MotivationEtObjectif>Motivation et objectif</MotivationEtObjectif>
              <LoremIpsumDolor>
                <BlankLine>{data.objectif}</BlankLine>
              </LoremIpsumDolor>
            </MotivationEtObjectifParent>
            <LeSalonEnChiffreParent>
              <LeSalonEn>Le salon en chiffre</LeSalonEn>
              <FrameContainer>
                <GroupContainer>
                  <GroupIcon alt="" src="/group1.svg" />
                  <TlchargerLeBrochure1>
                    <BlankLine>{data.visitor}</BlankLine>
                    <BlankLine>Visiteurs<br/>profesionnels</BlankLine>
                  </TlchargerLeBrochure1>
                </GroupContainer>
                <GroupContainer>
                  <GroupIcon alt="" src="/group1-2.svg" />
                  <TlchargerLeBrochure1>
                    <BlankLine>{data.exhibitor}</BlankLine>
                    <BlankLine>Exposants</BlankLine>
                  </TlchargerLeBrochure1>
                </GroupContainer>
                <GroupContainer>
                  <GroupIcon alt="" src="/group1-3.svg" />
                  <TlchargerLeBrochure1>
                    <BlankLine>{data.conference}</BlankLine>
                    <BlankLine>Conférences<br/>et ateliers</BlankLine>
                  </TlchargerLeBrochure1>
                </GroupContainer>
                <GroupContainer>
                  <GroupIcon alt="" src="/group1-4.svg" />
                  <TlchargerLeBrochure1>
                    <BlankLine>{data.innovation}</BlankLine>
                    <BlankLine>Innovations<br/>majeures</BlankLine>
                  </TlchargerLeBrochure1>
                </GroupContainer>
              </FrameContainer>
            </LeSalonEnChiffreParent>
          </FrameGroup>
          <SalonInner>
            <LoremIpsumDolorSitAmetCoParent>
              <LoremIpsumDolor1>
              {data.introsector}
              </LoremIpsumDolor1>
              <LesSecteursReprsents>Les secteurs représentés</LesSecteursReprsents>
              <FrameParent1>
                {
                  (data.sector)?(
                    data.sector.split(',').map((item, id)=>{
                      return(
                        <LoremIpsumDolorSitAmetCoWrapper id={id}>
                          <LoremIpsumDolor2>{item}</LoremIpsumDolor2>
                        </LoremIpsumDolorSitAmetCoWrapper>
                      )
                    })
                  ):(
                    <></>
                  )
                }
              </FrameParent1>
            </LoremIpsumDolorSitAmetCoParent>
          </SalonInner>
          <FrameParent2>
            <FrameIcon alt="" src="/frame-37.svg" />
            <Vnements>événements</Vnements>
            <RectangleGroup>
              <RectangleDiv />
              <MotivationEtObjectif>Conférences</MotivationEtObjectif>
              <LoremIpsumDolorSitAmetCoWrapper1>
                {/* <MotivationEtObjectif1>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et
                  massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
                  fringilla,
                </MotivationEtObjectif1> */}
              </LoremIpsumDolorSitAmetCoWrapper1>
            </RectangleGroup>
            <RectangleContainer>
              <RectangleDiv />
              <MotivationEtObjectif>Side events</MotivationEtObjectif>
              <LoremIpsumDolorSitAmetCoWrapper1>
                {/* <MotivationEtObjectif1>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et
                  massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
                  fringilla,
                </MotivationEtObjectif1> */}
              </LoremIpsumDolorSitAmetCoWrapper1>
            </RectangleContainer>
            <RectangleParent1>
              <RectangleDiv />
              <MotivationEtObjectif>Animations</MotivationEtObjectif>
              <LoremIpsumDolorSitAmetCoWrapper1>
                {/* <MotivationEtObjectif1>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et
                  massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
                  fringilla,
                </MotivationEtObjectif1> */}
              </LoremIpsumDolorSitAmetCoWrapper1>
            </RectangleParent1>
            <EllipseDiv />
          </FrameParent2>
          <FrameParent3>
            <PlanningDesConfrenceParent>
              <MotivationEtObjectif>Programme</MotivationEtObjectif>
              <LoremIpsumDolorSitAmetCoWrapper2>
                <MotivationEtObjectif1>Conférences et ateliers thématiques animés par des experts du secteur - Démonstrations de produits et services innovants - Rencontres BtoB et networking - Espace VIP pour les échanges privilégiés</MotivationEtObjectif1>
              </LoremIpsumDolorSitAmetCoWrapper2>
              <div
                style={{
                  paddingTop: 20,
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                }}
              >
                {
                  (data.program)?(
                    data.program.map((item, id)=>{
                      return(
                        <div
                          style={{
                            background: "white",
                            padding: 20,
                            borderRadius: 10,
                            width: 350,
                            color: "black",
                          }}
                        
                        >
                          <MotivationEtObjectif>{item.name}</MotivationEtObjectif>
                          <hr />
                          <div
                            style={{
                              display: "flex",
                              flexWrap:  "nowrap",
                            }}
                          >
                            <div>
                              date : {item.start}
                            </div>
                            <div>
                              lieu : {item.place}
                            </div>
                          </div>
                          <hr />
                          <div
                            style={{
                              display: "flex",
                              flexWrap:  "nowrap",
                            }}
                          >
                            {/* <div>
                              salle : {item.salle}
                            </div> */}
                            <div>
                              inscription : {item.link}
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ):(
                    <></>
                  )
                }
              </div>
              <div
                style={{
                  background: "linear-gradient(0deg, var(--color-gray), #0000)",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  bottom: 0,
                  left: 0
                }}
              />
              <GroupParent>
                {/* <FrameItem alt="" src="/group-2.svg" /> */}
                  <BtnBlackBg
                    to={"/timeline"}
                    style={{
                      position: "absolute",
                      bottom: 100,
                      right: 100
                    }}
                  >
                    Consulter
                  </BtnBlackBg>
              </GroupParent>
            </PlanningDesConfrenceParent>
            {/* <Planning>Programme</Planning> */}
          </FrameParent3>
          {/* <FrameParent4>
            <ThmatiquesDeConfrenceParent>
              <MotivationEtObjectif>Thématiques de conférence</MotivationEtObjectif>
              <LoremIpsumDolorSitAmetCoWrapper2>
                <MotivationEtObjectif1>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et
                  massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
                  fringilla,
                </MotivationEtObjectif1>
              </LoremIpsumDolorSitAmetCoWrapper2>
            </ThmatiquesDeConfrenceParent>
            <ThmatiquesDeConfrenceParent>
              <MotivationEtObjectif>Thématiques de conférence</MotivationEtObjectif>
              <LoremIpsumDolorSitAmetCoWrapper2>
                <MotivationEtObjectif1>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et
                  massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
                  fringilla,
                </MotivationEtObjectif1>
              </LoremIpsumDolorSitAmetCoWrapper2>
            </ThmatiquesDeConfrenceParent>
            <ThmatiquesDeConfrenceParent>
              <MotivationEtObjectif>Thématiques de conférence</MotivationEtObjectif>
              <LoremIpsumDolorSitAmetCoWrapper2>
                <MotivationEtObjectif1>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et
                  massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
                  fringilla,
                </MotivationEtObjectif1>
              </LoremIpsumDolorSitAmetCoWrapper2>
            </ThmatiquesDeConfrenceParent>
            <ThmatiquesDeConfrenceParent>
              <MotivationEtObjectif>Thématiques de conférence</MotivationEtObjectif>
              <LoremIpsumDolorSitAmetCoWrapper2>
                <MotivationEtObjectif1>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et
                  massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
                  fringilla,
                </MotivationEtObjectif1>
              </LoremIpsumDolorSitAmetCoWrapper2>
            </ThmatiquesDeConfrenceParent>
          </FrameParent4> */}
          <PartnersSection>
            <Partenariat>Partenariat</Partenariat>
            <div
              style={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <FrameParent5>
                <SponsorsParent>
                  <PartenairesInstitutionnels>Sponsors</PartenairesInstitutionnels>
                  <Ceb40d2700004f23850Parent>
                  {
                      (data.sponsor)?(
                        data.sponsor.map((item, id)=>{
                          return(
                            <NavLink target="_blank" to={{pathname: item.link}} id={id}>
                                <Ceb40d2700004f23850Icon
                                  alt=""
                                  src={item.logo}
                                />
                            </NavLink>
                          )
                        })
                      ):(
                        <></>
                      )
                    }
                  </Ceb40d2700004f23850Parent>
                </SponsorsParent>
                <PartenairesInstitutionnelsParent>
                  <PartenairesInstitutionnels>{`Partenaires institutionnels `}</PartenairesInstitutionnels>
                  <Ceb40d2700004f23850Parent>
                    {
                      (data.institutionnel)?(
                        data.institutionnel.map((item, id)=>{
                          return(
                            <NavLink target="_blank" to={{pathname: item.link}} id={id}>
                                <Ceb40d2700004f23850Icon
                                  alt=""
                                  src={item.logo}
                                />
                            </NavLink>
                          )
                        })
                      ):(
                        <></>
                      )
                    }
                  </Ceb40d2700004f23850Parent>
                </PartenairesInstitutionnelsParent>
              </FrameParent5>
              <FrameParent5>
                <PartenairesEntreprisesParent>
                  <PartenairesInstitutionnels>{`Partenaires entreprises `}</PartenairesInstitutionnels>
                  <Ceb40d2700004f23850Parent>
                    {
                        (data.partenaire)?(
                          data.partenaire.map((item, id)=>{
                            return(
                              <NavLink target="_blank" to={{pathname: item.link}} id={id}>
                                  <Ceb40d2700004f23850Icon
                                    alt=""
                                    src={item.logo}
                                  />
                              </NavLink>
                            )
                          })
                        ):(
                          <></>
                        )
                    }
                  </Ceb40d2700004f23850Parent>
                </PartenairesEntreprisesParent>
              </FrameParent5>
            </div>
          </PartnersSection>
        </HorizontalScroll>
      </SalonRoot>
    );
};

export default Salon;
