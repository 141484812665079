import React, {useEffect, useState, useRef} from 'react';
import styled from "styled-components";
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import { motion } from 'framer-motion';
import Navbar from '../subComponents/Navbar';
import Footer from '../components/Footer';
import { postApiContact } from '../api/api';

// import IntlTelInput from 'react-intl-tel-input';
// import 'intl-tel-input/build/css/intlTelInput.css';
// import 'react-intl-tel-input/dist/main.css';

const FormulaireDeContact = styled.div`
  position: relative;
  font-weight: 200;
`;
const DevenezNotrePartenaire = styled.span``;
const DevenezNotrePartenaireExpo = styled.b``;
const DevenezNotrePartenaireExpo1 = styled.div`
  margin: 0;
`;
const BlankLine = styled.p`
  margin: 0;
  font-size: var(--paragraph-size);
`;
const DevenezNotrePartenaireContainer = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-6xl);
`;
const FormulaireDeContactParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-lg);
`;

const InputField = styled.input`
  width: 100%;
  padding: var(--padding-xs);
  margin: var(--margin-xs) 0;
  box-sizing: border-box;
`;

const Exposant = styled.div`
  position: relative;
`;

const ChoiceWrapper = styled.div`
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
  border-radius: var(--br-8xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xl);
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
  border-radius: var(--br-8xs);
  background-color: var(--primary);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xl);
  color: var(--white);
  cursor: pointer;
`;

const PartenaireWrapper = styled.div`
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
  border-radius: var(--br-8xs);
  background-color: var(--white);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xl);
`;
const FrameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-18xl);
  text-align: center;
  color: var(--primary);
`;
const Prnom = styled.div`
  position: relative;
  font-weight: 600;
`;
const Koloina = styled.div`
  align-self: stretch;
  position: relative;
  font-weight: 600;
  color: var(--primary);
`;
const PrnomParent = styled.div`
  align-self: stretch;
  border-bottom: 2px solid var(--primary);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px var(--padding-3xs);
  gap: var(--gap-3xs);
`;
const FrameDiv = styled.div`
  align-self: stretch;
  border-radius: var(--br-xl);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-11xl);
  gap: 25px;
`;
const FrameWrapper = styled.div`
  align-self: stretch;
  box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.1);
  border-radius: var(--br-mini);
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const NosContacts = styled.div`
  position: relative;
  font-size: var(--heading-4-size);
  font-weight: 200;
`;
const FimexpohazovatocomFiaexpo = styled.b`
  align-self: stretch;
  position: relative;
  color: var(--primary);
`;
const NosContactsParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px var(--padding-3xs);
  gap: var(--gap-3xs);
`;
const FrameWrapper1 = styled.div`
  align-self: stretch;
  border-radius: var(--br-xl);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-11xl);
`;
const FrameGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-21xl);
  font-size: var(--paragraph-size);
`;
const FrameParent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-7xl-3);
  box-sizing: border-box;
  gap: var(--gap-31xl);
  max-width: 800px;
`;
const WelcomeSectionInner = styled.div`
  flex: 1;
  border-radius: 13.14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
const WelcomeSection = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: var(--padding-81xl);
`;
const ContactFormRoot = styled(motion.div)`
  width: 100%;
  position: relative;
  background-color: var(--color-whitesmoke-100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--heading-4-size);
  color: var(--secondary);
  font-family: var(--paragraph);
`;

const Contact = () => {
    const [choice, setChoice] = useState("Exposant")
    const [message, setMessage] = useState("")
    const firstname = useRef(null)
    const lastname = useRef(null)
    const email = useRef(null)
    const phone = useRef(null)

    const submit = () => {

        // var phonenumber = formattedPhoneValue
        // phonenumber = phonenumber.substring(1)
        // const re = /\s/gi;
        // phonenumber = phonenumber.replace(re, "")

        const data = {
            "phone" : phone.current.value,
            "email" : email.current.value,
            "firstname" : firstname.current.value,
            "lastname" : lastname.current.value,
            "choice" : choice,
        }

        console.log({data: data})

        postApiContact(data).then(data => {
            if(data){
                if(data.error === 0){
                    setMessage(data.message)
                }
                else if(data.error === 1){
                    setMessage(data.message)
                }else{
                    setMessage("Check your internet connection")
                }
            }else{
                setMessage("Check your internet connection")
            }
        })
    }
  return (
    <>
        <Navbar menuColor={"black"} id="Navbar" />
        <ContactFormRoot>
        <WelcomeSection>
            <WelcomeSectionInner>
            <FrameParent>
                <FormulaireDeContactParent>
                <FormulaireDeContact>Formulaire de contact</FormulaireDeContact>
                <DevenezNotrePartenaireContainer>
                    <DevenezNotrePartenaireExpo1>
                    <DevenezNotrePartenaireExpo>
                        <DevenezNotrePartenaire>
                        Devenez notre partenaire, exposant ou panéliste !
                        </DevenezNotrePartenaire>
                    </DevenezNotrePartenaireExpo>
                    </DevenezNotrePartenaireExpo1>
                    <BlankLine>
                    <DevenezNotrePartenaire>
                        <DevenezNotrePartenaireExpo>
                        &nbsp;
                        </DevenezNotrePartenaireExpo>
                    </DevenezNotrePartenaire>
                    </BlankLine>
                    <DevenezNotrePartenaireExpo1>
                    <DevenezNotrePartenaire>
                        <DevenezNotrePartenaire>
                        Merci de votre intérêt pour notre entreprise. Remplissez
                        ce formulaire et notre équipe commerciale vous contactera
                        rapidement pour discuter des opportunités de partenariat,
                        d'exposition ou de participation en tant que panéliste.
                        </DevenezNotrePartenaire>
                    </DevenezNotrePartenaire>
                    </DevenezNotrePartenaireExpo1>
                </DevenezNotrePartenaireContainer>
                </FormulaireDeContactParent>
                <FrameGroup>
                <FrameContainer>
                    <ChoiceWrapper
                        style={{
                            backgroundColor: (choice === "Exposant")?("var(--primary)"):("white"),
                            color: (choice === "Exposant")?("white"):("var(--primary)"),
                        }}
                        onClick={()=>{
                            setChoice("Exposant")
                        }}
                    >
                        <Exposant>Exposant</Exposant>
                    </ChoiceWrapper>
                    <ChoiceWrapper
                        style={{
                            backgroundColor: (choice === "Partenaire")?("var(--primary)"):("white"),
                            color: (choice === "Partenaire")?("white"):("var(--primary)"),
                        }}
                        onClick={()=>{
                            setChoice("Partenaire")
                        }}
                    >
                        <Exposant>Partenaire</Exposant>
                    </ChoiceWrapper>
                    <ChoiceWrapper
                        style={{
                            backgroundColor: (choice === "Paneliste")?("var(--primary)"):("white"),
                            color: (choice === "Paneliste")?("white"):("var(--primary)"),
                        }}
                        onClick={()=>{
                            setChoice("Paneliste")
                        }}
                    >
                        <Exposant>Paneliste</Exposant>
                    </ChoiceWrapper>
                </FrameContainer>
                <FrameWrapper>
                    <FrameDiv>
                        <PrnomParent>
                            <Prnom>Prénom</Prnom>
                            <InputField name="firstname" ref={firstname} type="text" placeholder="Prénom" />
                        </PrnomParent>
                        <PrnomParent>
                            <Prnom>Nom</Prnom>
                            <InputField name="lastname" ref={lastname} type="text" placeholder="Nom" />
                        </PrnomParent>
                        <PrnomParent>
                            <Prnom>Téléphone</Prnom>
                            <InputField name="phone" ref={phone} type="number" placeholder="Téléphone" />
                        </PrnomParent>
                        <PrnomParent>
                            <Prnom>Adresse email</Prnom>
                            <InputField name="email" ref={email} type="email" placeholder="Adresse email" />
                        </PrnomParent>
                        <ButtonWrapper
                            onClick={()=>{
                                submit()
                            }}
                        >
                            <Exposant>Envoyer</Exposant>
                        </ButtonWrapper>
                    </FrameDiv>
                </FrameWrapper>
                <FrameWrapper>
                    <FrameWrapper1>
                    <NosContactsParent>
                        <NosContacts>Nos contacts</NosContacts>
                        <Prnom>Raison commerciale</Prnom>
                        <FimexpohazovatocomFiaexpo>
                        <DevenezNotrePartenaireExpo1>
                            fim.expo@hazovato.com
                        </DevenezNotrePartenaireExpo1>
                        <DevenezNotrePartenaireExpo1>
                            fia.expo@hazovato.com
                        </DevenezNotrePartenaireExpo1>
                        <DevenezNotrePartenaireExpo1>
                            habitat.expo@hazovato.com
                        </DevenezNotrePartenaireExpo1>
                        <DevenezNotrePartenaireExpo1>
                            +261 34 07 212 43 | 44 | 46
                        </DevenezNotrePartenaireExpo1>
                        </FimexpohazovatocomFiaexpo>
                        <Prnom>Autres</Prnom>
                        <FimexpohazovatocomFiaexpo>
                        <DevenezNotrePartenaireExpo1>
                            coordination.expo@hazovato.com
                        </DevenezNotrePartenaireExpo1>
                        <DevenezNotrePartenaireExpo1>
                            +261 34 07 212 47
                        </DevenezNotrePartenaireExpo1>
                        </FimexpohazovatocomFiaexpo>
                    </NosContactsParent>
                    </FrameWrapper1>
                </FrameWrapper>
                </FrameGroup>
            </FrameParent>
            </WelcomeSectionInner>
        </WelcomeSection>
        </ContactFormRoot>
        <Footer />
    </>
  );
};

export default Contact;
