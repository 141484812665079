import styled from "styled-components";
import { IoLogoFacebook, IoLogoTiktok, IoLogoYoutube, IoLogoLinkedin, IoLogoInstagram } from 'react-icons/io5';
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const Discutons = styled.div`
  width: 296px;
  position: relative;
  font-size: var(--font-size-31xl);
  line-height: 48px;
  font-weight: 900;
  font-family: var(--font-montserrat);
  display: inline-block;
`;
const B = styled.b`
  width: 327px;
  position: relative;
  display: inline-block;
`;
const Coordinationexpohazovatocom = styled.a`
  width: 327px;
  position: relative;
  color: inherit;
  display: inline-block;
  text-decoration: none;
`;
const LogoFacebookIcon = styled.img`
  width: 31.9px;
  position: relative;
  height: 31.1px;
  overflow: hidden;
  flex-shrink: 0;
`;
const LogoInstagramIcon = styled.img`
  width: 31.1px;
  position: relative;
  height: 31.1px;
  overflow: hidden;
  flex-shrink: 0;
`;
const LogoFacebookParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 19.44px;
`;
const DiscutonsParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-sm);
`;
const QuiSommesNous = styled.div`
  width: 296px;
  position: relative;
  font-size: var(--font-size-6xl);
  line-height: 48px;
  font-weight: 900;
  font-family: var(--font-montserrat);
  display: inline-block;
`;
const B1 = styled.div`
  width: 327px;
  position: relative;
  display: inline-block;
`;
const FrameParent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px var(--padding-9xs);
`;
const ShowSectionInner = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;
const CopyrightHazovato2024Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;
const ShowSection = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 45px;
  z-index: 0;
`;

const ImageRepetitive = styled.div`
  width: 100%;
  height: 75px;
  background-image: url('/patern.webp');
  background-repeat: repeat-x;
  background-size: auto 75px;
`;

const FooterWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 75px;
  box-sizing: border-box;
  text-align: left;
  font-size: var(--paragraph-size);
  color: var(--third);
  font-family: var(--paragraph);
`;

const FooterRoot = styled.div`
  background-color: var(--secondary);
`;

const color = {
    dark: "#0009",
    light: "#fff9",
    orange: "#fe9955",
}

const Footer = () => {
  return (
    <FooterRoot>
        <ImageRepetitive />
      <FooterWrapper>
        <ShowSection>
          <ShowSectionInner>
            <FrameParent>
              <DiscutonsParent>
                <Discutons>Discutons</Discutons>
                <br/>
                <B>+261 34 07 212 47</B>
                <Coordinationexpohazovatocom
                  href="mailto:coordination.expo@hazovato.com"
                  target="_blank"
                >
                  coordination.expo@hazovato.com
                </Coordinationexpohazovatocom>
                <LogoFacebookParent>
                  <NavLink target="_blank" to={{pathname:"https://www.facebook.com/forelloexpo"}}>
                    <IoLogoFacebook
                        size={30}
                        color={ color.light }
                    />
                  </NavLink>
                  <NavLink target="_blank" to={{pathname:"https://www.instagram.com/forelloexpo/"}}>
                    <IoLogoInstagram
                        size={30}
                        color={ color.light }
                    />
                  </NavLink>
                  <NavLink target="_blank" to={{pathname:"https://www.linkedin.com/company/forelloexpo"}}>
                    <IoLogoLinkedin
                        size={30}
                        color={ color.light }
                    />
                  </NavLink>
                  {/* <NavLink target="_blank" to={{pathname:"https://calendly.com/forello-expo/rendez-vous-one-to-one"}}>
                    <IoLogoTiktok
                        size={30}
                        color={ color.light }
                    />
                  </NavLink> */}
                  <NavLink target="_blank" to={{pathname:"https://www.youtube.com/@ForelloExpo"}}>
                    <IoLogoYoutube
                        size={30}
                        color={ color.light }
                    />
                  </NavLink>
                </LogoFacebookParent>
              </DiscutonsParent>
              <DiscutonsParent>
                <QuiSommesNous>Qui sommes nous?</QuiSommesNous>
                <B1>Forello Expo</B1>
                <B1>Nos offres</B1>
                <B1>Les salons</B1>
              </DiscutonsParent>
              <DiscutonsParent>
                <QuiSommesNous>2024.</QuiSommesNous>
                <B1>Nos objectifs</B1>
                <B1>{`Nos investissements `}</B1>
                <B1>Nos partenaires</B1>
                <B1>Participer aux salons</B1>
              </DiscutonsParent>
            </FrameParent>
          </ShowSectionInner>
          <ShowSectionInner>
            <FrameParent>
              <DiscutonsParent>
                <QuiSommesNous>à découvrir</QuiSommesNous>
                <NavLink to="/blog">
                  <B1>Blog</B1>
                </NavLink>
                <NavLink to="/timeline">
                  <B1>Calendrier</B1>
                </NavLink>
                {/* <NavLink to="/podcast">
                  <B1>Podcast</B1>
                </NavLink> */}
                <NavLink to="/galerie">
                  <B1>Galerie</B1>
                </NavLink>
              </DiscutonsParent>
              <DiscutonsParent>
                <QuiSommesNous>Assistance</QuiSommesNous>
                <B1>Service client</B1>
                <B1>Politique</B1>
                {/* <B1>RGPD</B1> */}
              </DiscutonsParent>
              <CopyrightHazovato2024Wrapper>
                <B1>Copyright Hazovato - 2024</B1>
              </CopyrightHazovato2024Wrapper>
            </FrameParent>
          </ShowSectionInner>
        </ShowSection>
      </FooterWrapper>
    </FooterRoot>
  );
};

export default Footer;
