import React, {useState, useEffect} from "react"
import { useParams, NavLink } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components";
import { motion } from 'framer-motion'
import Navbar from '../subComponents/Navbar'
import Footer from "../components/Footer";
import { getBlogsFromApi } from '../api/api'

const AnnuaireDesExposants = styled.div`
  position: relative;
  line-height: 48px;
  font-weight: 900;
  display: inline-block;
`;
const FrameChild = styled.div`
  width: 196px;
  position: relative;
  background-color: var(--primary);
  height: 10px;
`;
const FrameItem = styled.div`
  width: 117px;
  position: relative;
  background-color: #febd1c;
  height: 10px;
`;
const RectangleParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const AnnuaireDesExposantsParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px var(--padding-9xs);
  gap: var(--gap-5xl);
`;
const Go = styled.div`
  width: 187px;
  position: relative;
  display: inline-block;
  flex-shrink: 0;
`;
const GoWrapper = styled.div`
  align-self: stretch;
  width: 91px;
  background-color: var(--primary);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xl);
  box-sizing: border-box;
  cursor: pointer;
`;
const FrameWrapper = styled.div`
  flex: 1;
  box-shadow: 0px 30px 50px -10px rgba(0, 0, 0, 0.1);
  border-radius: var(--br-mini);
  background-color: var(--white);
  height: 60px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
const FrameParent = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-11xl);
  text-align: center;
  font-size: var(--paragraph-size);
  color: var(--white);
  font-family: var(--paragraph);
`;
const WelcomeSection = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-81xl) var(--padding-81xl) var(--padding-21xl);
  gap: var(--gap-11xl);
  font-family: var(--font-montserrat);
`;

const RectangleIcon = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
`;
const RectangleWrapper = styled.div`
  align-self: stretch;
  height: 203.4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 0;
`;
const LeSpcialisteEn = styled.b`
    width: 265px;
    position: relative;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3; /* Number of lines to show */
`;
const Raphco = styled.div`
  width: 262.2px;
  position: relative;
  font-size: var(--font-size-xl-9);
  color: var(--color-black);
  display: inline-block;
`;
const Country = styled.div`
  position: relative;
`;
const CountryParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  font-size: var(--font-size-sm-1);
`;
const LeSpcialisteEnRecrutementParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-7xl-3);
  gap: var(--gap-5xs);
  z-index: 1;
`;

const FrameGroup = styled(NavLink)`
  width: 317.5px;
  box-shadow: 0px 26.3px 43.79px -4.38px rgba(0, 0, 0, 0.1);
  border-radius: 13.14px;
  background-color: var(--white);
  height: 369.9px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 4;
  padding: 0;
`;

const WelcomeSection2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-81xl);
  gap: var(--gap-xl);
  font-size: var(--font-size-base-7);
  color: var(--color-darkgray);
`;
const AnnuaireRoot = styled(motion.div)`
  width: 100%;
  position: relative;
  background-color: #f8f4f1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-31xl);
  color: var(--secondary);
  font-family: var(--paragraph);
`;

const container = {

    hidden: {opacity:0},
    show: {
      opacity:1,

      transition:{
        staggerChildren: 0.2,
        duration: 0.5,
      }
    }

  }

const BlogPage = () => {
    const [input, setInput] = useState('all')
    const [data, setData] = useState([])

    useEffect(() => {
        window.scrollTo(0,0)
        getBlogsFromApi("all").then((data)=>{
            if(data !== undefined){
                setData(data)
            }
        })
    }, []);

    const updateResult = (input) => {
        setInput(input)
        getBlogsFromApi(input).then((data)=>{
            if(data !== undefined){
                setData(data)
            }
        })
    }

  return (
    <>
        <Navbar menuColor={"black"} id="Navbar" />
        <AnnuaireRoot
            variants={container}
            initial='hidden'
            animate='show'
            exit={{
                opacity:0, transition:{duration: 0.5}
            }}
        >
        <WelcomeSection>
            <AnnuaireDesExposantsParent>
                <AnnuaireDesExposants>Blog Forello Expo</AnnuaireDesExposants>
                <RectangleParent>
                    <FrameChild />
                    <FrameItem />
                </RectangleParent>
            </AnnuaireDesExposantsParent>
            <FrameParent>
                <FrameWrapper>
                    <input
                        placeholder="Mots clés"
                        type="text"
                        style={{
                            padding: "var(--padding-mini)",
                            width: "100%",
                            height: "100%",
                        }}
                        onChange={(v)=>{
                                if((v.target.value).replace(" ", "") === ""){
                                    updateResult(input)
                                }else{
                                    updateResult(input)
                                }
                            }
                        }
                    />
                    <GoWrapper
                        onClick={()=>{
                            updateResult(input)
                        }}
                    >
                    <Go>GO</Go>
                    </GoWrapper>
                </FrameWrapper>
            </FrameParent>
        </WelcomeSection>
        <WelcomeSection2>
            {
                data.map((item, id) => {
                    return(
                        <FrameGroup index={id} to={"/blog/"+item.id}>
                            <RectangleWrapper>
                                <RectangleIcon alt="" src={item.image} />
                            </RectangleWrapper>
                            <LeSpcialisteEnRecrutementParent>
                                <LeSpcialisteEn>
                                    {item.title}
                                </LeSpcialisteEn>
                                <Raphco>@{item.company}</Raphco>
                                <CountryParent>
                                <Country>par {item.author}</Country>
                                {/* <Country>{item.date}</Country> */}
                                </CountryParent>
                            </LeSpcialisteEnRecrutementParent>
                        </FrameGroup>
                    )
                })
            }
        </WelcomeSection2>
        </AnnuaireRoot>
        <Footer />
    </>
  );
};

export default BlogPage;
