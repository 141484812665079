import React, { useEffect, useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import {lightTheme} from '../components/Themes';
import LogoComponent from '../subComponents/LogoComponent';
import { motion } from 'framer-motion'
import Dev2 from '../subComponents/Dev2';
import '../assets/customCss/blog.css'
import { IoArrowBack } from 'react-icons/io5';
import { TimelineData } from '../data/TimelineData';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import DOMPurify from 'dompurify';

const Box = styled(motion.div)`
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 9rem;
    padding-bottom: 5rem;
`

const WebDiv = styled.div`
    text-align: justify;
    padding-left: 3.5rem;
    @media (min-width: 600px){
        padding-left: 0;
    }
`

const container = {

    hidden: {opacity:0},
    show: {
      opacity:1,
  
      transition:{
        staggerChildren: 0.2,
        duration: 0.5,
      }
    }
  
  }

const TimelineNext = () => {
    const { id } = useParams("id");
    const [data, setData] = useState([])

    
    useEffect(() => {
        window.scrollTo(0,0)
        if(id){
            setData(TimelineData[id])
        }
    }, [id]);
    
    console.log(data.data)

    return (
        <ThemeProvider theme={lightTheme}>
            <Box
                variants={container}
                initial='hidden'
                animate='show'
                exit={{
                    opacity:0, transition:{duration: 0.5}
                }}
            >
                <LogoComponent theme='light'/>
                <Dev2 />
                <div
                    style={{
                        maxWidth: 800,
                        marginLeft: 20,
                        marginRight: 20
                        }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <NavLink 
                            to="/timeline"
                            style={{
                                paddingLeft: 0,
                                paddingRight: 20,
                            }}
                        >
                            <IoArrowBack
                                size={30}
                                color="#888"
                            />
                        </NavLink>
                        <h4
                            style={{fontSize: "calc(40px + 0.5vw)", fontWeight: "bolder"}}
                        >
                            {data.title}
                        </h4>
                    </div>
                    <hr />
                    <br />
                    {
                        (data.data)?(
                            <WebDiv dangerouslySetInnerHTML={ {__html: DOMPurify.sanitize(data.data)} } />
                            ):(
                                <></>
                            )
                    }
                </div>
            </Box>
        </ThemeProvider>
    )
}

export default TimelineNext
