import React, {useState, useEffect} from "react"
import { useParams, NavLink } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components";
import { motion } from 'framer-motion'
import Navbar from '../subComponents/Navbar'
import Footer from "../components/Footer";
import { getBlogFromApi } from '../api/api'
import { IoArrowBack } from 'react-icons/io5';
import '../assets/customCss/blog.css'
import DOMPurify from 'dompurify';


const GaleriePhotos = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 48px;
  font-weight: 900;
`;
const AlbumAnimations = styled.p`
  align-self: stretch;
  position: relative;
  font-size: var(--paragraph-size);
  font-family: var(--paragraph);
`;

const Text = styled.div`
  position: relative;
  font-size: var(--paragraph-size);
  font-family: var(--paragraph);
`;

const GaleriePhotosSitlmParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
`;
const WebDiv = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 20px 0;
`;
const LoremIpsumDolorSitAmetCoWrapper = styled.div`
  align-self: stretch;
  position: relative;
  text-align: justify;
  font-size: var(--font-size-xl);
  font-family: var(--paragraph);
`;
const FrameParent = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width : 700px;
  // padding: 60px;
  // background-color: white;
  // border-radius : 40px
`;
const WelcomeSection = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-81xl) var(--padding-81xl) var(--padding-21xl);
`;
const GalerieRoot = styled(motion.div)`
  width: 100%;
  position: relative;
  background-color: var(--color-whitesmoke-100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--heading-4-size);
  color: var(--secondary);
  background-color: var(--third);
  font-family: var(--paragraph);
  background-color: white
`;

const container = {

    hidden: {opacity:0},
    show: {
      opacity:1,
  
      transition:{
        staggerChildren: 0.2,
        duration: 0.5,
      }
    }
  
  }

const BlogDetail = () => {
    const {id} = useParams('id')
    const [data, setData] = useState([])

    useEffect(() => {
        window.scrollTo(0,0)
        getBlogFromApi(id).then((data)=>{
            if(data !== undefined){
                setData(data)
            }
        })
    }, []);
  return (
    <>
        <Navbar menuColor={"black"} id="Navbar" />
        <GalerieRoot
            variants={container}
            initial='hidden'
            animate='show'
            exit={{
                opacity:0, transition:{duration: 0.5}
            }}
        >
            <WelcomeSection>
                <FrameParent>
                <GaleriePhotosSitlmParent>
                    <GaleriePhotos>{data.title}</GaleriePhotos>
                    <AlbumAnimations>par <b>{data.author}</b> de l'entreprise <b>{data.company}</b></AlbumAnimations>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <NavLink
                            to="/blog"
                            style={{
                                paddingLeft: 0,
                                paddingRight: 20,
                            }}
                        >
                            <IoArrowBack
                                size={30}
                                color="#888"
                            />
                        </NavLink>
                        <Text>Revenir vers <b>Blog</b></Text>
                    </div>
                <br/>
                </GaleriePhotosSitlmParent>
                <LoremIpsumDolorSitAmetCoWrapper>
                <img src={data.image} alt={data.company} style={{width: "100%", height: "auto", borderRadius: 20}}/>
                <br/>
                {
                    (data.body)?(
                        <WebDiv dangerouslySetInnerHTML={ {__html: DOMPurify.sanitize(data.body)} } />
                    ):(
                        <></>
                    )
                }
                </LoremIpsumDolorSitAmetCoWrapper>
                </FrameParent>
            </WelcomeSection>
        </GalerieRoot>
        <Footer />
    </>
  );
};

export default BlogDetail;
