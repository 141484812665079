import React from 'react'
import styled from 'styled-components'
import LogoComponent from '../subComponents/LogoComponent'
import SocialIcons from '../subComponents/SocialIcons'
import BigTitle from '../subComponents/BigTitlte'
import { motion } from 'framer-motion'
import Dev from '../subComponents/Dev'
import Navbar from '../subComponents/Navbar'
import { Parallax } from 'react-scroll-parallax'
import { Partenaires } from '../data/PartenaireData'

const MainContainer = styled(motion.div)`
    background: "${props => props.theme.body}";
    width: 100vw;
    position: relative;
    h2,h3,h4,h5,h6{
        font-family:'Karla', sans-serif ;
        font-weight:500;
    }
    padding-bottom: 10rem;
    overflow: hidden
    scrollbar-width: none; // Firefox
    -ms-overflow-style: none; // IE and Edge
    &::-webkit-scrollbar {
        display: none; // WebKit (Chrome, Safari, etc.)
    }
`

const Container = styled.div`
    padding: 2rem;
`

// Framer-motion config
const container = {

    hidden: {opacity:0},
    show: {
      opacity:1,
  
      transition:{
        staggerChildren: 0.2,
        duration: 0.5,
      }
    }
  
  }

const InDev = () => {

    return (
            <MainContainer
                variants={container}
                initial='hidden'
                animate='show'
                exit={{
                    opacity:0, transition:{duration: 0.5}
                }}
            >
                <Container>
                    <Navbar />
                </Container>
                <div
                    style={{
                        padding: "10rem",
                        fontSize: 30
                    }}
                >
                    <h1>
                        En cours de construction . . . 
                    </h1>
                </div>
                <BigTitle text="InDev" bottom="5rem" right="6rem" color="#0002"/>
            </MainContainer>
    )
}

export default InDev
