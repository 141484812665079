import React from 'react'
import styled from 'styled-components'
import Me from '../assets/Images/Art.png'

const Container = styled.div`
    filter: opacity(0.7);
    position: fixed;
    display: flex;
    align-items: center;
	z-index:3;
    left: 1.3rem;
	bottom: 1.2rem;
`

const Logo = styled.img`
	height: 2rem;
	width: 2rem;
`

const Dev = (props) => {
    return (
        <Container>
            <Logo src={Me} />
        </Container>
    )
}

export default Dev
