import React, {useState, useEffect} from "react"
import { useParams, NavLink } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components";
import { motion } from 'framer-motion'
import Navbar from '../subComponents/Navbar'
import Footer from "../components/Footer";
import { getAttendeeFromApi, postAttendeeFromApi } from '../api/api'

const AnnuaireDesExposants = styled.div`
  width: 427px;
  position: relative;
  line-height: 48px;
  font-weight: 900;
  display: inline-block;
`;
const FrameChild = styled.div`
  width: 196px;
  position: relative;
  background-color: var(--primary);
  height: 10px;
`;
const FrameItem = styled.div`
  width: 117px;
  position: relative;
  background-color: #febd1c;
  height: 10px;
`;
const RectangleParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const TousLesExposants = styled.b`
  width: 442px;
  position: relative;
  font-size: var(--paragraph-size);
  display: inline-block;
  font-family: var(--paragraph);
`;
const AnnuaireDesExposantsParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-9xs);
  gap: var(--gap-5xl);
`;
const FrameInner = styled.div`
  display: flex;
  flex: 1;
  box-shadow: 0px 30px 50px -10px rgba(0, 0, 0, 0.1);
  border-radius: var(--br-mini);
  background-color: var(--white);
  height: 60px;
  overflow: hidden;
  width: 100%;
  padding: var(--padding-mini);
  align-items: center;
`;
const Go = styled.div`
  position: relative;
  display: inline-block;
  flex-shrink: 0;
`;
const GoWrapper = styled.div`
  align-self: stretch;
  width: 91px;
  background-color: var(--primary);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xl);
  box-sizing: border-box;
  cursor: pointer;
`;
const FrameWrapper = styled.div`
  box-shadow: 0px 30px 50px -10px rgba(0, 0, 0, 0.1);
  border-radius: var(--br-mini);
  background-color: var(--brand);
  height: 60px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width : 100px;
  cursor : pointer;
`;
const FrameParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-11xl);
  text-align: left;
  font-size: var(--paragraph-size);
  font-family: var(--paragraph);
`;
const WelcomeSection = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-81xl) var(--padding-81xl) var(--padding-21xl);
  gap: var(--gap-11xl);
  font-family: var(--font-montserrat);
`;
const Leadership = styled.div`
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
`;
const LeadershipWrapper = styled.div`
  box-shadow: 0px 22.7px 75.63px -7.56px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  background-color: var(--white);
  height: 32px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xl-7);
  box-sizing: border-box;
`;
const WelcomeSection1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0px var(--padding-81xl);
  gap: var(--gap-xl);
  font-size: var(--font-size-mini-4);
  color: var(--primary);
`;
const RectangleIcon = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
`;
const RectangleWrapper = styled.div`
  align-self: stretch;
  height: 203.4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 0;
`;
const LeSpcialisteEn = styled.b`
    width: 265px;
    position: relative;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3; /* Number of lines to show */
`;
const Raphco = styled.div`
  width: 262.2px;
  position: relative;
  font-size: var(--font-size-xl-9);
  color: var(--color-black);
  display: inline-block;
`;
const Country = styled.div`
  position: relative;
`;
const CountryParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  font-size: var(--font-size-sm-1);
`;
const LeSpcialisteEnRecrutementParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-7xl-3);
  gap: var(--gap-5xs);
  z-index: 1;
`;
const Icon = styled.img`
  width: 190.8px;
  position: absolute;
  margin: 0 !important;
  top: 24px;
  right: 24px;
  border-radius: var(--br-6xs-7);
  height: 76.4px;
  width: auto;
  object-fit: contain;
  z-index: 2;
  background-color: white
`;
const FrameGroup = styled(NavLink)`
  width: 317.5px;
  box-shadow: 0px 26.3px 43.79px -4.38px rgba(0, 0, 0, 0.1);
  border-radius: 13.14px;
  background-color: var(--white);
  height: 369.9px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 4;
  padding: 0;
`;
const FrameContainer = styled.div`
  width: 317.5px;
  box-shadow: 0px 26.3px 43.79px -4.38px rgba(0, 0, 0, 0.1);
  border-radius: 13.14px;
  background-color: var(--white);
  height: 369.9px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 3;
`;
const FrameDiv = styled.div`
  width: 317.5px;
  box-shadow: 0px 26.3px 43.79px -4.38px rgba(0, 0, 0, 0.1);
  border-radius: 13.14px;
  background-color: var(--white);
  height: 369.9px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 2;
`;
const FrameParent1 = styled.div`
  width: 317.5px;
  box-shadow: 0px 26.3px 43.79px -4.38px rgba(0, 0, 0, 0.1);
  border-radius: 13.14px;
  background-color: var(--white);
  height: 369.9px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
`;
const FrameParent2 = styled.div`
  width: 317.5px;
  box-shadow: 0px 26.3px 43.79px -4.38px rgba(0, 0, 0, 0.1);
  border-radius: 13.14px;
  background-color: var(--white);
  height: 369.9px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 0;
`;
const WelcomeSection2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-81xl);
  gap: var(--gap-xl);
  font-size: var(--font-size-base-7);
  color: var(--color-darkgray);
`;
const AnnuaireRoot = styled(motion.div)`
  width: 100%;
  position: relative;
  background-color: #f8f4f1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-31xl);
  color: var(--secondary);
  font-family: var(--paragraph);
`;

const container = {

    hidden: {opacity:0},
    show: {
      opacity:1,
  
      transition:{
        staggerChildren: 0.2,
        duration: 0.5,
      }
    }
  
  }

const Attendee = () => {
    const [data, setData] = useState([])
    const [updStatus, setUpdStatus] = useState([0])

    useEffect(() => {
        window.scrollTo(0,0)
        getAttendeeFromApi().then((data)=>{
            if(data !== undefined){
                setData(data)
            }
        })
    }, [updStatus]);

    console.log(data)

    const isJSONString = (str) => {
        try {
          JSON.parse(str);
          return true;
        } catch (e) {
          return false;
        }
      }

      const updateResult = (id) => {
        postAttendeeFromApi(id).then((data)=>{
            if(data.message === "Invitation sent successfully!"){
                setUpdStatus(!updStatus)
            }
        })
    }


  return (
    <>
        <Navbar menuColor={"var(--brand)"} id="Navbar" />
        <AnnuaireRoot
            variants={container}
            initial='hidden'
            animate='show'
            exit={{
                opacity:0, transition:{duration: 0.5}
            }}
        >
            <WelcomeSection>
                <AnnuaireDesExposantsParent>
                <AnnuaireDesExposants>Liste des inscrits aux side-events</AnnuaireDesExposants>
                <RectangleParent>
                    <FrameChild />
                    <FrameItem />
                </RectangleParent>
                <TousLesExposants>Portail d'envoie des invitations.</TousLesExposants>
                </AnnuaireDesExposantsParent>
                {
                      data.map((item, id)=>{
                        return(
                          <FrameParent>
                              <FrameInner
                                  // onChange={(input)=>{
                                  //         if((input.target.value).replace(" ", "") === ""){
                                  //             updateResult(loc, categorie, 'all', 0)
                                  //         }else{
                                  //             updateResult(loc, categorie, input.target.value, 0)
                                  //         }
                                  //     }
                                  // }
                              >
                                  {item.firstname+" "+item.lastname}
                              </FrameInner>
                              <FrameInner
                                  // onChange={(input)=>{
                                  //         if((input.target.value).replace(" ", "") === ""){
                                  //             updateResult(loc, categorie, 'all', 0)
                                  //         }else{
                                  //             updateResult(loc, categorie, input.target.value, 0)
                                  //         }
                                  //     }
                                  // }
                              >
                                  {item.phone +"/"+ item.email+"/"+item.entity}
                              </FrameInner>
                              <FrameWrapper>
                                <NavLink target="_blank" to={{pathname:"https://api.forello-expo.com/public/api/previewInvitation/"+item.id}}>
                                    <Go>PDF</Go>
                                </NavLink>
                              </FrameWrapper>
                              <FrameWrapper
                                  style={{
                                    backgroundColor: (item.status == 0)?("var(--brand)"):("var(--green)")
                                  }}
                                  onClick={()=>{
                                      updateResult(item.id)
                                  }}
                              >
                                  <Go>{(item.status == 0)?("Envoyer"):(item.status + ' envoi(s)')}</Go>
                              </FrameWrapper>
                          </FrameParent>
                        )
                      })
                }
            </WelcomeSection>
        </AnnuaireRoot>
        <Footer />
    </>
  );
};

export default Attendee;
