// blog data

export const Partenaires = [
    {
        id:1,
        name:"Raph&Co",
        logo:require("../assets/Images/Tiers/3.png")
    },
    {
        id:2,
        name:"Full of Solution",
        logo:require("../assets/Images/Tiers/1.png")
    },
    {
        id:3,
        name:"EIL",
        logo:require("../assets/Images/Tiers/2.png")
    },
    {
        id:4,
        name:"ZOOM",
        logo:require("../assets/Images/Tiers/4.png")
    },
]