import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { motion } from 'framer-motion';
import Navbar from '../subComponents/Navbar';
import Footer from "../components/Footer";
import { getPhotosFromApi } from '../api/api';

const GaleriePhotos = styled.div`
  max-width: 600px;
  text-align: center;
  position: relative;
  line-height: 48px;
  font-weight: 900;
  display: inline-block;
`;

const FrameChild = styled.div`
  width: 196px;
  position: relative;
  background-color: var(--primary);
  height: 10px;
`;

const FrameItem = styled.div`
  width: 117px;
  position: relative;
  background-color: var(--color-orange-100);
  height: 10px;
`;

const RectangleParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const RetrouvezLesMoments = styled.b`
  width: 442px;
  position: relative;
  font-size: var(--paragraph-size);
  display: inline-block;
  font-family: var(--paragraph);
`;

const GaleriePhotosParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px var(--padding-9xs);
  gap: var(--gap-5xl);
`;

const WelcomeSection = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-81xl) var(--padding-81xl) var(--padding-21xl);
  text-align: left;
`;

const FrameInner = styled.div`
    position: absolute;
    width: 276px;
    height: 276px;
    flex-shrink: 0;
    border-radius: 58px;
`;

const FrameInner1 = styled.div`
    position: absolute;
    width: 275.565px;
    height: 275.565px;
    transform: rotate(-19.089deg);
    flex-shrink: 0;
    border-radius: 58px;
`;

const FrameInner2 = styled.div`
    position: absolute;
    width: 275.565px;
    height: 275.565px;
    transform: rotate(-9.238deg);
    flex-shrink: 0;
    border-radius: 58px;
    box-shadow: 0px 16px 50px 0px rgba(0, 0, 0, 0.40);
`;

const WelcomeSection1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-21xl) var(--padding-41xl);
  gap: 20px 80px;
  font-size: var(--paragraph-size);
  font-family: var(--paragraph);
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 8px;
  padding: 8px;
  width: 100%;
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 100%; /* Makes the container a square */
`;

const StyledImage = styled(motion.img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  /* Aspect ratio handling */
  @media (min-aspect-ratio: 2 / 3) {
    aspect-ratio: 2 / 3;
  }

  @media (max-aspect-ratio: 2 / 3) {
    aspect-ratio: 1 / 1;
  }
`;

const Placeholder = styled.div`
  background-color: #e0e0e005;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const GalerieRoot = styled(motion.div)`
  width: 100%;
  position: relative;
  background-color: var(--color-whitesmoke-100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--font-size-31xl);
  color: var(--white);
  background-color: var(--color-black);
  font-family: var(--font-montserrat);
`;

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      duration: 0.5,
    }
  }
}

const GalerieDetail = () => {
  const { event, eventtype } = useParams();
  const [data, setData] = useState([]);
  const [loadedImages, setLoadedImages] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    getPhotosFromApi(event, eventtype).then((data) => {
      if (data !== undefined) {
        setData(data);
      }
    });
  }, [event, eventtype]);

  const handleImageLoad = (id) => {
    setLoadedImages((prevLoadedImages) => ({
      ...prevLoadedImages,
      [id]: true,
    }));
  };

  return (
    <>
      <Navbar menuColor={"white"} id="Navbar" />
      <GalerieRoot
        variants={container}
        initial='hidden'
        animate='show'
        exit={{
          opacity: 0, transition: { duration: 0.5 }
        }}
      >
        <WelcomeSection>
          <GaleriePhotosParent>
            <GaleriePhotos>
              <span>
                {event}
                <br />
                {eventtype}
              </span>
            </GaleriePhotos>
            <RectangleParent>
              <FrameChild />
              <FrameItem />
            </RectangleParent>
          </GaleriePhotosParent>
        </WelcomeSection>
        <WelcomeSection1>
          <Grid>
            {data.map((photo) => (
              <ImageWrapper key={photo.id}>
                {!loadedImages[photo.id] && <Placeholder />}
                <StyledImage
                  src={photo.image}
                  alt=""
                  initial={{ opacity: 0 }}
                  animate={{ opacity: loadedImages[photo.id] ? 1 : 0 }}
                  transition={{ duration: 0.5 }}
                  onLoad={() => handleImageLoad(photo.id)}
                />
              </ImageWrapper>
            ))}
          </Grid>
        </WelcomeSection1>
      </GalerieRoot>
    </>
  );
};

export default GalerieDetail;
