import React from 'react'
import styled from 'styled-components'
import Me from '../assets/Images/Art.png'

const Container = styled.div`
    filter: opacity(0.7);
    position: fixed;
    display: flex;
    align-items: center;
	z-index:3;
    left: 1rem;
	bottom: 1.2rem;
`

const Logo = styled.img`
	height: 1.8rem;
	width: 1.8rem;
`

const Dev = (props) => {
    return (
        <Container>
            <Logo src={Me} />
            <p style={{paddingLeft: 5, fontSize: 13}}>
                Caringly ❤ crafted by <br></br><a target="_blank" rel="noreferrer" style={{color: "#0088ff", fontWeight: "bold"}} href='https://abioud.polytechnique.mg'>Abioud RABEZANAHARY</a>
            </p>
        </Container>
    )
}

export default Dev
