import { motion } from 'framer-motion'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { DarkTheme } from '../components/Themes'
import { IoEgg, IoHeartCircle, IoChatbubbleEllipses, IoInformationCircle, IoNewspaper, IoTimer, IoEasel } from 'react-icons/io5';
import '../assets/customCss/nav.css'

const color = {
    color1: "#9251ac",
    color2: "#f6a4ec",
    color3: "#fe9955",
    color4: "#445ac0",
    color5: "#f6647e",
    color6: "#fe4499",
}

const NavLink1 = styled(NavLink)`

`
const Int1 = styled.p`
    filter: opacity(0);
    transition: all 0.5s ease;

    ${NavLink1}:hover &{
        filter: opacity(1);
    }
`

const NavLink2 = styled(NavLink)`

`
const Int2 = styled.p`
    filter: opacity(0);
    transition: all 0.5s ease;

    ${NavLink2}:hover &{
        filter: opacity(1);
    }
`

const NavLink3 = styled(NavLink)`

`
const Int3 = styled.p`
    filter: opacity(0);
    transition: all 0.5s ease;

    ${NavLink3}:hover &{
        filter: opacity(1);
    }
`

const NavLink4 = styled(NavLink)`

`
const Int4 = styled.p`
    filter: opacity(0);
    transition: all 0.5s ease;

    ${NavLink4}:hover &{
        filter: opacity(1);
    }
`

const NavLink5 = styled(NavLink)`

`
const Int5 = styled.p`
    filter: opacity(0);
    transition: all 0.5s ease;

    ${NavLink5}:hover &{
        filter: opacity(1);
    }
`

const NavLink6 = styled(NavLink)`

`
const Int6 = styled.p`
    filter: opacity(0);
    transition: all 0.5s ease;

    ${NavLink6}:hover &{
        filter: opacity(1);
    }
`

const NavLink7 = styled(NavLink)`

`
const Int7 = styled.p`
    filter: opacity(0);
    transition: all 0.5s ease;

    ${NavLink7}:hover &{
        filter: opacity(1);
    }
`

const Icons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    position: fixed;
    bottom: 4rem;
    left: 1.5rem;
    z-index:3;
    &>*:not(:last-child){
        margin: 0.1rem 0;
    }
`

// const Line = styled(motion.span)`
//     width: 2px;
//     height: 8rem;
//     background-color: ${props => props.color === 'dark' ? DarkTheme.text : DarkTheme.body  };
// `

const NavIcons = (props) => {
    return (
        <Icons>
            <NavLink1 style={{color:'inherit', display: 'flex', flexDirection: 'row', alignItems: 'center'}}  to="/">
                <motion.div
                    initial={{transform:"scale(0)"}}
                    animate={{scale:[0,1.3,1]}}
                    transition={{type:'spring', duration:1, delay:1}}
                    style={{
                        paddingBottom: 0
                    }}
                >
                    <IoEgg
                        size={30}
                        color={ color.color1 }
                    />
                </motion.div>
                <Int1 
                    className="bg-white-round"
                    style={{fontSize:11, color: props.theme === "dark" ? DarkTheme.text  : DarkTheme.body}}
                >
                    Accueil
                </Int1>
            </NavLink1>
            <NavLink5 style={{color:'inherit', display: 'flex', flexDirection: 'row', alignItems: 'center'}}  to="/blog">
                <motion.div
                    initial={{transform:"scale(0)"}}
                    animate={{scale:[0,1.3,1]}}
                    transition={{type:'spring', duration:1, delay:1.2}}
                    style={{
                        paddingBottom: 0
                    }}
                >
                    <IoNewspaper
                        size={30}
                        color={ color.color5 }
                    />
                </motion.div>
                <Int5 
                    className="bg-white-round"
                    style={{fontSize:11, color: props.theme === "dark" ? DarkTheme.text : DarkTheme.body}}
                >
                    Blog
                </Int5>
            </NavLink5>
            <NavLink4 style={{color:'inherit', display: 'flex', flexDirection: 'row', alignItems: 'center'}}  to="/timeline">
                <motion.div
                    initial={{transform:"scale(0)"}}
                    animate={{scale:[0,1.3,1]}}
                    transition={{type:'spring', duration:1, delay:1.4}}
                    style={{
                        paddingBottom: 0
                    }}
                >
                    <IoTimer
                        size={30}
                        color={ color.color4 }
                    />
                </motion.div>
                <Int4 
                    className="bg-white-round"
                    style={{fontSize:11, color: props.theme === "dark" ? DarkTheme.text  : DarkTheme.body}}
                >
                    Evènements
                </Int4>
            </NavLink4>
            <NavLink7 style={{color:'inherit', display: 'flex', flexDirection: 'row', alignItems: 'center'}}  to="/course">
                <motion.div
                    initial={{transform:"scale(0)"}}
                    animate={{scale:[0,1.3,1]}}
                    transition={{type:'spring', duration:1, delay:1.6}}
                    style={{
                        paddingBottom: 0
                    }}
                >
                    <IoEasel
                        size={30}
                        color={ color.color3 }
                    />
                </motion.div>
                <Int7
                    className="bg-white-round"
                    style={{fontSize:11, color: props.theme === "dark" ? DarkTheme.text  : DarkTheme.body}}
                >
                    Cours
                </Int7>
            </NavLink7>
            {/* <NavLink2 style={{color:'inherit', display: 'flex', flexDirection: 'row', alignItems: 'center'}}  to="/about">
                <motion.div
                    initial={{transform:"scale(0)"}}
                    animate={{scale:[0,1.3,1]}}
                    transition={{type:'spring', duration:1, delay:2}}
                    style={{
                        paddingBottom: 0
                    }}
                >
                    <IoInformationCircle
                        size={30}
                        color={ color.color2 }
                    />
                </motion.div>
                <Int2 
                    className="bg-white-round"
                    style={{fontSize:11, color: props.theme === "dark" ? DarkTheme.text  : DarkTheme.body}}
                >
                    A propos
                </Int2>
            </NavLink2> */}
            <NavLink6 style={{color:'inherit', display: 'flex', flexDirection: 'row', alignItems: 'center'}}  target="_blank" to={{pathname:"mailto:contact.aizakoa@gmail.com"}}>
                <motion.div
                    initial={{transform:"scale(0)"}}
                    animate={{scale:[0,1.3,1]}}
                    transition={{type:'spring', duration:1, delay:2.2}}
                    style={{
                        paddingBottom: 0
                    }}
                >
                    <IoChatbubbleEllipses
                        size={30}
                        color={ color.color6 }
                    />
                </motion.div>
                <Int6 
                    className="bg-white-round"
                    style={{fontSize:11, color: props.theme === "dark" ? DarkTheme.text  : DarkTheme.body}}
                >
                    Contact
                </Int6>
            </NavLink6>

            {/* <Line color={props.theme}
                initial={
                    {
                        height:0
                    }
                }
                animate={{
                    height: '8rem'
                }}
                transition={{
                    type:'spring', duration:1, delay:0.8
                }}
            /> */}
        </Icons>
    )
}

export default NavIcons
