import { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { motion } from 'framer-motion'

const NosOffres = styled.b`
  align-self: stretch;
  position: relative;
  line-height: 100%;
  text-transform: uppercase;
`;
const NosOffresWrapper = styled.div`
  width: 300px;
  border-bottom: 6px solid var(--brand);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px var(--padding-xl);
`;
const EspaceDeVente = styled.b`
  align-self: stretch;
  position: relative;
  line-height: 100%;
`;
const LoremIpsumDolor = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-mini);
`;
const EspaceDeVenteParent = styled(NavLink)`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
`;
const Offer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
`;
const SalonsHeader = styled.div`
  align-self: stretch;
  border-bottom: 6px solid var(--brand);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px var(--padding-xl);
`;
const SalonBody = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
`;
const Salons = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
`;

const Megamenu = styled(motion.div)`
  margin: 0 !important;
  position: fixed;
  top: 70px;
  left: ${props => `calc(50% - ${props.width}px / 2)`};
  border-radius: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;
  padding: var(--padding-31xl);
  box-sizing: border-box;
  gap: var(--gap-21xl);
  z-index: 0;
  // font-size: var(--font-size-6xl);
  color: var(--secondary);
  font-family: var(--paragraph);
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.25);
  cursor: auto;
  transition: background-image 0.5s ease;
`;

const container = {
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      staggerChildren: 0.2,
      duration: 0.5,
    },
  },
  show: {
    opacity: 1,
    y: -5,
    transition: {
      opacity: {
        duration: 0.5,
      },
      y: {
        type: "spring",
        stiffness: 300,
        damping: 10,
        mass: 1,
        staggerChildren: 0.2,
      },
    },
  },
};

export const OfferMegamenusection = (props) => {
  const menuRef = useRef(null);
  const [menuWidth, setMenuWidth] = useState(0);

  useEffect(() => {
    if (menuRef.current) {
      setMenuWidth(menuRef.current.offsetWidth);
    }
  }, []);
  return (
    <Megamenu
      style={{
        width: 1060,
        backgroundColor: "var(--color-white)",
      }}
      variants={container}
      initial='hidden'
      animate='show'
      exit={{
          opacity:0, transition:{duration: 0.5}
      }}
      width={menuWidth}
      ref={menuRef}
    >
      <Offer>
        <NosOffresWrapper>
          <NosOffres>Nos offres</NosOffres>
        </NosOffresWrapper>
        <EspaceDeVenteParent to={"/"}>
          <EspaceDeVente>Espace de vente</EspaceDeVente>
          <LoremIpsumDolor>{`Choisir entre un stand baché avec prise électrique et eclairage ou votre structure suivant votre conception. `}</LoremIpsumDolor>
        </EspaceDeVenteParent>
        <EspaceDeVenteParent to={"/"}>
          <EspaceDeVente>Communication</EspaceDeVente>
          <LoremIpsumDolor>{`Une stratégie de communication sur mesure pour une présence sur tous nos canaux : médias, physique et digitale. Nous vous garantissons une visibilité avant, pendant et après nos événements.`}</LoremIpsumDolor>
        </EspaceDeVenteParent>
        <EspaceDeVenteParent to={"/"}>
          <EspaceDeVente>
            Echanges professionels et autres services
          </EspaceDeVente>
          <LoremIpsumDolor>{`Salle de conférence, espace BtoB, espace de recrutement, etc.`}</LoremIpsumDolor>
        </EspaceDeVenteParent>
      </Offer>
      <Salons>
        <SalonsHeader>
          <NosOffres>Nos salons</NosOffres>
        </SalonsHeader>
        <SalonBody>
          <EspaceDeVenteParent to={"/salon/SITLM"}>
            <EspaceDeVente>
              Salon International des Transports, de la Logistique et de la
              Manutention
            </EspaceDeVente>
            <LoremIpsumDolor>{`L'évènement de toutes les composantes du transport qu'il soit aérien, fluvial, maritime ou terrestre. Et par conséquent, les filières et secteurs qui gravitent autour de ce secteur : la logistique, la supply chain, les infrastructures.`}</LoremIpsumDolor>
          </EspaceDeVenteParent>
          <EspaceDeVenteParent to={"/salon/FIA"}>
            <EspaceDeVente>
              Foire Internationale de l’Agriculture
            </EspaceDeVente>
            <LoremIpsumDolor>{`La rencontre multisectorielle et inclusive du secteur de l'élevage, de l'agriculture et de la pêche. Une plateforme d'opportunités pour tous les acteurs du domaine.`}</LoremIpsumDolor>
          </EspaceDeVenteParent>
          <EspaceDeVenteParent to={"/salon/FIM"}>
            <EspaceDeVente>
              Foire Internationale de Madagascar
            </EspaceDeVente>
            <LoremIpsumDolor>{`La foire économique malagasy inscrite à l'agenda mondial des salons. Considérée comme la manifestation commerciale multisectorielle majeure de Madagascar et du Sud-Ouest de l'Océan Indien.`}</LoremIpsumDolor>
          </EspaceDeVenteParent>
          <EspaceDeVenteParent to={"/salon/SIH"}>
            <EspaceDeVente>Salon International de l’Habitat</EspaceDeVente>
            <LoremIpsumDolor>{`L'évènement qui incarne l'innovation et l'engagement du secteur du bâtiment et de l'immobilier face aux besoins d'accompagnement, d'adaptation et de maintenance de l'aménagement d'un logement.`}</LoremIpsumDolor>
          </EspaceDeVenteParent>
        </SalonBody>
      </Salons>
    </Megamenu>
  );
};

export const ParticipateMegamenusection = (props) => {
  const menuRef = useRef(null);
  const [menuWidth, setMenuWidth] = useState(0);
  const [imageUrl, setImageUrl] = useState("images/excellence.jpg")

  useEffect(() => {
    if (menuRef.current) {
      setMenuWidth(menuRef.current.offsetWidth);
    }
  }, []);
  return (
    <Megamenu
      style={{
        width: 1060,
        background: "white"
      }}
      variants={container}
      initial='hidden'
      animate='show'
      exit={{
          opacity:0, transition:{duration: 0.5}
      }}
      width={menuWidth}
      ref={menuRef}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "calc(1060px - 300px - 100px)",
          // width: 500,
          height: "100%",
          backgroundImage: `url("${imageUrl}")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          transition: "all 0.5s ease",
          opacity: 0.7
        }}
      >
      </div>
      <Offer
        style={{flex: 1}}
      >
        <NosOffresWrapper>
          <NosOffres>Participer</NosOffres>
        </NosOffresWrapper>
        <EspaceDeVenteParent
          to={"/contact"}
          onMouseOver={()=>{
            setImageUrl("images/solidarity.jpg")
          }}
          // onMouseLeave={()=>{
          //   setImageUrl("images/excellence.jpg")
          // }}
        >
          <EspaceDeVente>
            Devenir partenaire
          </EspaceDeVente>
          <LoremIpsumDolor>{`Rejoignez-nous en tant que partenaire et devenez un contributeur clé du succès de chacun de nos salons.`}</LoremIpsumDolor>
        </EspaceDeVenteParent>
        <EspaceDeVenteParent
          to={"/contact"}
          onMouseOver={()=>{
            setImageUrl("images/microphone.jpg")
          }}
          // onMouseLeave={()=>{
          //   setImageUrl("images/excellence.jpg")
          // }}
        >
          <EspaceDeVente>
            Devenir intervenant
          </EspaceDeVente>
          <LoremIpsumDolor>{`Devenez intervenant lors de nos conférences thématiques. Ciblez une large gamme de panélistes et de prospects de votre secteur.`}</LoremIpsumDolor>
        </EspaceDeVenteParent>
        <EspaceDeVenteParent
          to={"/contact"}
          onMouseOver={()=>{
            setImageUrl("images/image-2.jpg")
          }}
          // onMouseLeave={()=>{
          //   setImageUrl("images/excellence.jpg")
          // }}
        >
          <EspaceDeVente>
            Devenir exposant
          </EspaceDeVente>
          <LoremIpsumDolor>{`Réservez votre stand et augmentez votre chiffre d'affaires. Avec plus de 10 000 visiteurs par salons, gagnez en visibilité et en rentabilité.`}</LoremIpsumDolor>
        </EspaceDeVenteParent>
      </Offer>
    </Megamenu>
  );
};
