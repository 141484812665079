import React, {useState, useEffect} from "react"
import { useParams, NavLink } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components";
import { motion } from 'framer-motion'
import Navbar from '../subComponents/Navbar'
import Footer from "../components/Footer";
import { getAlbumsFromApi } from '../api/api'


const GaleriePhotos = styled.div`
  width: 427px;
  position: relative;
  line-height: 48px;
  font-weight: 900;
  display: inline-block;
`;
const FrameChild = styled.div`
  width: 196px;
  position: relative;
  background-color: var(--primary);
  height: 10px;
`;
const FrameItem = styled.div`
  width: 117px;
  position: relative;
  background-color: var(--color-orange-100);
  height: 10px;
`;
const RectangleParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const RetrouvezLesMoments = styled.b`
  width: 442px;
  position: relative;
  font-size: var(--paragraph-size);
  display: inline-block;
  font-family: var(--paragraph);
`;
const GaleriePhotosParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-9xs);
  gap: var(--gap-5xl);
`;
const WelcomeSection = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-81xl) var(--padding-81xl) var(--padding-21xl);
  text-align: left;
`;
const FrameInner = styled.div`
    position: absolute;
    width: 276px;
    height: 276px;
    flex-shrink: 0;
    border-radius: 58px;
`;

const FrameInner1 = styled.div`
    position: absolute;
    width: 275.565px;
    height: 275.565px;
    transform: rotate(-19.089deg);
    flex-shrink: 0;
    border-radius: 58px;
`;

const FrameInner2 = styled.div`
    position: absolute;
    width: 275.565px;
    height: 275.565px;
    transform: rotate(-9.238deg);
    flex-shrink: 0;
    border-radius: 58px;
    box-shadow: 0px 16px 50px 0px rgba(0, 0, 0, 0.40);
`;
const RectangleGroup = styled.div`
    width: 274px;
    height: 273px;
`;
const AnimationExtrieur = styled.b`
  width: 278px;
  position: relative;
  display: inline-block;
`;
const FrameContainer = styled(NavLink)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
`;
const WelcomeSection1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-21xl) var(--padding-41xl);
  gap: 20px 80px;
  font-size: var(--paragraph-size);
  font-family: var(--paragraph);
`;
const GalerieRoot = styled(motion.div)`
  width: 100%;
  position: relative;
  background-color: var(--color-whitesmoke-100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: var(--font-size-31xl);
  color: var(--secondary);
  background-color: var(--third);
  font-family: var(--font-montserrat);
`;

const container = {

    hidden: {opacity:0},
    show: {
      opacity:1,
  
      transition:{
        staggerChildren: 0.2,
        duration: 0.5,
      }
    }
  
  }

const Galerie = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        window.scrollTo(0,0)
        getAlbumsFromApi().then((data)=>{
            if(data !== undefined){
                setData(data)
            }
        })
    }, []);
  return (
    <>
        <Navbar menuColor={"var(--brand)"} id="Navbar" />
        <GalerieRoot
            variants={container}
            initial='hidden'
            animate='show'
            exit={{
                opacity:0, transition:{duration: 0.5}
            }}
        >
        <WelcomeSection>
            <GaleriePhotosParent>
            <GaleriePhotos>Galerie photos</GaleriePhotos>
            <RectangleParent>
                <FrameChild />
                <FrameItem />
            </RectangleParent>
            <RetrouvezLesMoments>
                Retrouvez les moments forts des événements à Forello Expo
            </RetrouvezLesMoments>
            </GaleriePhotosParent>
        </WelcomeSection>
        {
            data.map((item, index)=>{
                return(
                    <>
                        <GaleriePhotos>{item.event}</GaleriePhotos>
                        <WelcomeSection1>
                            {
                                item.data.map((it, id)=>{
                                    return(
                                        <FrameContainer to={"/galerie/" + item.event + "/" + it.eventtype}>
                                            <RectangleGroup>
                                                <FrameInner2
                                                    style={{
                                                        background: `url(${it.img1}) lightgray 50% / cover no-repeat`
                                                    }}
                                                />
                                                <FrameInner1
                                                    style={{
                                                        background: `url(${it.img2}) lightgray 50% / cover no-repeat`
                                                    }}
                                                />
                                                <FrameInner
                                                    style={{
                                                        background: `url(${it.img3}) lightgray 50% / cover no-repeat`
                                                    }}
                                                />
                                            </RectangleGroup>
                                            <AnimationExtrieur>{it.eventtype}</AnimationExtrieur>
                                        </FrameContainer>
                                    )
                                })
                            }
                        </WelcomeSection1>
                    </>
                )
            })
        }
        </GalerieRoot>
        <Footer />
    </>
  );
};

export default Galerie;
