import { useState } from "react";
import styled from "styled-components";

const FoldSection = styled.div`
    display: flex;
    width: 100%;
    padding-top: 150px;
    padding-bottom: 100px;
    padding-left: 100px;
    padding-right: 100px;
    flex-direction: column;
    align-items: left;
    gap: 50px;
`;
const FoldInner = styled.div`
    display: flex;
    height: 400px;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius : 15px;
    overflow: hidden;
`;
const Items = styled.div`
    align-self: stretch;
    width: 100%;
    // flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 72%), url("${props=>props.url}"), lightgray 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    transition: all 1.5s ease;
    border: 1px solid rgba(255, 255, 255, 0.50);

    &:hover {
        align-self: stretch;
        width: 512px;
        overflow: hidden;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 72%), url("${props=>props.url}"), lightgray 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
    }
`;
const ItemText = styled.div`
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 35px;
    position: relative;
    gap: 0px 20px;
`

const H1 = styled.div`
    // width: 213px;
    color: var(--Third, #EBEBEB);
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: absolute;
    margin: 0 !important;
    bottom: -13px;
    left: 35px;
    display: inline-block;
    transform: rotate(-90deg);
    transform-origin: 0 0;
    z-index: 0;
`;
const P = styled.p`
    width: 356px;
    position: absolute;
    margin: 0 !important;
    bottom: 35px;
    left: 108px;
    display: inline-block;
    z-index: 1;
    color: var(--Third, #EBEBEB);
    font-family: Inter;
    font-size: var(--paragraph-size);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0;
    transition: all 1s ease;


    // Use the parent selector to show P when Items is hovered
    ${Items}:hover & {
        opacity: 1;
    }
`;


const Fold = (props) => {
    return (
        <FoldSection>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                }}
            >
                <div
                    style={{
                    fontSize: 30,
                    lineHeight: "48px",
                    fontWeight: 900,
                    fontFamily: "var(--paragraph)",
                    display: "inline-block"
                    }}
                >
                    Forello Expo
                </div>
                <div
                    style={{
                    fontSize: "var(--font-size-31xl)",
                    lineHeight: "48px",
                    fontWeight: 900,
                    fontFamily: "var(--font-montserrat)",
                    display: "inline-block",
                    color: "var(--brand)"
                    }}
                >
                NOS VALEURS
                </div>
                {/* <div
                    style={{
                    fontSize: 20,
                    lineHeight: "48px",
                    fontFamily: "var(--paragraph)",
                    display: "inline-block"
                    }}
                >
                    qui nous guident
                </div> */}
            </div>
            <FoldInner>
                <Items url="images/excellence.jpg">
                    <ItemText>
                        <H1>
                            Excellence
                        </H1>
                        <P>
                        Nous visons à offrir une expérience exceptionnelle aux exposants, visiteurs et partenaires, en reconnaissance de leur confiance. Nous sommes déterminés à relever les défis quotidiens, acceptant l'échec comme un élément essentiel de l'innovation. Chaque critique est une opportunité d'amélioration et nous sommes guidés par la quête de l'excellence, une valeur fondamentale du groupe HAZOVATO.
                        </P>
                    </ItemText>
                </Items>
                <Items url="images/innovation.jpg">
                    <ItemText>
                        <H1>
                            Innovation
                        </H1>
                        <P>
                        Nous nous démarquons par notre innovation dans la conception et la réalisation d'événements, ainsi que par la diversité des exposants que nous accueillons. Notre équipe proactive adapte continuellement notre offre en fonction des actualités, des enjeux et des tendances économiques.
                        </P>
                    </ItemText>
                </Items>
                <Items url="images/transparency.jpg">
                    <ItemText>
                        <H1>
                            Transparence
                        </H1>
                        <P>
                        Notre objectif principal est de mettre en lumière les compétences et les réalisations, tant individuelles qu'entrepreneuriales, afin de lever les ambiguïtés entourant les investissements étrangers à Madagascar. Nous fondons notre approche sur la confiance, qui repose sur une communication transparente, des procédures claires et une meilleure compréhension mutuelle entre les individus, les entreprises et les nations.
                        </P>
                    </ItemText>
                </Items>
                <Items url="images/responsability.jpg">
                    <ItemText>
                        <H1>
                            Responsabilité
                        </H1>
                        <P>
                        Nous sommes tous conscients de notre responsabilité à l'égard de l'économie, de la société et de l'environnement. Chacun de nos actes a un impact sur autrui et sur la planète qui nous abrite. En tant qu'entreprise, nous avons la responsabilité de remplir notre mission envers nos clients, nos partenaires et nos employés. Cette responsabilité est au cœur de nos succès et de nos échecs, et elle est indissociable de nos autres valeurs fondamentales.
                        </P>
                    </ItemText>
                </Items>
                <Items url="images/solidarity.jpg">
                    <ItemText>
                        <H1>
                            Solidarité
                        </H1>
                        <P>
                        La solidarité est fondamentale pour le tissu social, favorisant les échanges et les opportunités économiques. Nous encourageons la solidarité entre individus, entreprises privées et publiques, ainsi qu'envers les plus vulnérables. Nos événements proposent des espaces accessibles aux jeunes, aux femmes et aux entreprises engagées dans le développement durable, tout en soutenant des œuvres caritatives grâce à une partie de nos revenus.
                        </P>
                    </ItemText>
                </Items>
            </FoldInner>
        </FoldSection>
    );
};

export default Fold;