import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import {lightTheme} from '../components/Themes';
import LogoComponent from '../subComponents/LogoComponent';
import SocialIcons from '../subComponents/SocialIcons';
import { motion } from 'framer-motion'
import Dev2 from '../subComponents/Dev2';
import BigTitle from '../subComponents/BigTitlte'
import '../assets/customCss/Timeline.scss'
import { TimelineData } from '../data/TimelineData';
import { NavLink } from 'react-router-dom';
import Navbar from '../subComponents/Navbar';

const Box = styled(motion.div)`
    background-color: ${props => props.theme.body};
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8rem;
`

const container = {

    hidden: {opacity:0},
    show: {
      opacity:1,
  
      transition:{
        staggerChildren: 0.2,
        duration: 0.5,
      }
    }
  
  }

const TimelinePage = () => {

    return (
        <ThemeProvider theme={lightTheme}>
            <Navbar menuColor={"black"} id="Navbar" />
            <Box
                variants={container}
                initial='hidden'
                animate='show'
                exit={{
                    opacity:0, transition:{duration: 0.5}
                }}
            >
                <LogoComponent theme='light'/>
                <SocialIcons theme='light'/>
                <Dev2 />
                <BigTitle text="Timeline" top="80%" right="30%" color="#0002"/>
                <div className="timeline">
                    {
                        (TimelineData)?(
                            TimelineData.map((item, index)=>{
                                return(
                                    <div key={index} className={"timeline__event  animated fadeInUp delay-3s timeline__event--type"+item.type}>
                                        {/* <div className="timeline__event__icon ">
                                            Icon
                                        </div> */}
                                        <div className="timeline__event__date">
                                            <div>
                                                <span>
                                                    {item.date}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="timeline__event__content ">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <div>
                                                    <div className="timeline__event__title">
                                                        {item.title}
                                                    </div>
                                                    <div className="timeline__event__description">
                                                        <hr />
                                                        <p>
                                                            {item.summary}
                                                        </p>
                                                        <p>
                                                            {"| Durée: "+ item.time +" | Lieu: "+item.place +" | "}
                                                        </p>
                                                    </div>
                                                    {
                                                        (item.data !== undefined)?(
                                                            <NavLink 
                                                                to={"/timeline/"+item.id}
                                                            >
                                                                Voir détails
                                                                {/* <IoArrowForward
                                                                    size={30}
                                                                    color="#888"
                                                                /> */}
                                                            </NavLink>
                                                        ):(
                                                            <></>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        ):(
                            <></>
                        )
                    }
                </div>
            </Box>
        </ThemeProvider>
    )
}

export default TimelinePage
