import React, {useState, useEffect} from "react"
import { useParams, NavLink } from "react-router-dom/cjs/react-router-dom";
import styled from "styled-components";
import { motion } from 'framer-motion'
import Navbar from '../subComponents/Navbar'
import { getCompaniesFromApi, getCategoriesFromApi, getSubcatsFromApi, getLocalisationsFromApi, getSingleCompanyFromApi, getCdcFromApi } from '../api/api'

import L from 'leaflet';
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet'
import "leaflet/dist/leaflet.css";

const icon = L.icon({ iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png" });

const Raphco = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 48px;
  font-weight: 900;
`;
const FrameChild = styled.div`
  width: 196px;
  position: relative;
  background-color: var(--primary);
  height: 10px;
`;
const FrameItem = styled.div`
  width: 117px;
  position: relative;
  background-color: var(--color-orange-100);
  height: 10px;
`;
const RectangleParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const RaphcoParent = styled.div`
  align-self: stretch;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
  border-radius: var(--br-mini);
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl);
  gap: var(--gap-5xl);
`;
const SloganDeLa = styled.b`
  align-self: stretch;
  position: relative;
`;
const SloganDeLa1 = styled.div`
  align-self: stretch;
  position: relative;
`;
const SloganDeLaFoireInternationParent = styled.div`
  align-self: stretch;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
  border-radius: var(--br-mini);
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl);
  gap: var(--gap-xs);
  font-size: var(--paragraph-size);
  font-family: var(--paragraph);
`;
const WelcomeSection = styled.div`
  align-self: stretch;
  width: 525px;
  overflow-y: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-81xl) var(--padding-21xl) var(--padding-21xl);
  box-sizing: border-box;
  gap: var(--gap-11xl);
  z-index: 2;
`;
const DetailanChild = styled.div`
  align-self: stretch;
  width: 655px;
  z-index: 1;
`;
const DetailanItem = styled.div`
  width: 100vw;
  position: absolute;
  margin: 0 !important;
  top: 0px;
  left: 0px;
  height: 100vh;
  z-index: 0;
`;
const DetailanRoot = styled(motion.div)`
  background-color: var(--third);
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  text-align: left;
  font-size: var(--font-size-31xl);
  color: var(--secondary);
  font-family: var(--font-montserrat);
`;

const Icon = styled.img`
  margin: 0 !important;
  border-radius: var(--br-6xs-7);
  height: 76.4px;
  width: auto;
  object-fit: contain;
  background-color: white
`;

const container = {

    hidden: {opacity:0},
    show: {
      opacity:1,
  
      transition:{
        staggerChildren: 0.2,
        duration: 0.5,
      }
    }
  
  }

const DetailAn = () => {

    const { id } = useParams()
    const [data, setData] = useState([])
    const [dt, setDt] = useState([])

    useEffect(() => {
        window.scrollTo(0,0)
        getSingleCompanyFromApi(id).then((data)=>{
            setData(data)
        })
    }, []);

    const isJSONString = (str) => {
        try {
          JSON.parse(str);
          return true;
        } catch (e) {
          return false;
        }
      }


  return (
    <>
        <Navbar menuColor={"white"} id="Navbar" />
        <DetailanRoot
            variants={container}
            initial='hidden'
            animate='show'
            exit={{
                opacity:0, transition:{duration: 0.5}
            }}
        >
        <WelcomeSection>
            {
                (data.image)?(
                    <RaphcoParent
                        style={{
                            background: "url('https://api.forello-expo.com/public/storage/"+data.image[0]+"') lightgray 50% / cover no-repeat"
                        }}
                    >
                        <Icon alt="" src={data.logo} />
                    </RaphcoParent>
                ):(
                    <RaphcoParent
                        style={{
                            background: "lightgray 50% / cover no-repeat"
                        }}
                    >
                        <Icon alt="" src={data.logo} />
                    </RaphcoParent>
                )
            }

            <RaphcoParent>
                <Raphco>{data.corporate_name}</Raphco>
            </RaphcoParent>
            <SloganDeLaFoireInternationParent>
                <SloganDeLa1>{data.tagline}</SloganDeLa1>
            </SloganDeLaFoireInternationParent>
            <SloganDeLaFoireInternationParent>
                <SloganDeLa>
                    Description
                </SloganDeLa>
                <SloganDeLa1>{data.activities}</SloganDeLa1>
            </SloganDeLaFoireInternationParent>
            <SloganDeLaFoireInternationParent>
                <SloganDeLa>
                    Adresse
                </SloganDeLa>
                <SloganDeLa1>{data.adresse+", "+data.city+", "+data.country}</SloganDeLa1>
            </SloganDeLaFoireInternationParent>
            <SloganDeLaFoireInternationParent>
                <SloganDeLa>
                    Contact
                </SloganDeLa>
                <SloganDeLa1>
                    <span>
                        {data.phone}
                    </span>
                    <br/>
                    <span>
                        {data.email}
                    </span>
                </SloganDeLa1>
            </SloganDeLaFoireInternationParent>
            <SloganDeLaFoireInternationParent>
                <SloganDeLa>
                    Siteweb
                </SloganDeLa>
                <SloganDeLa1>{data.website}</SloganDeLa1>
            </SloganDeLaFoireInternationParent>
            <SloganDeLaFoireInternationParent>
                <SloganDeLa>
                    Réseaux sociaux
                </SloganDeLa>
                <SloganDeLa1>{data.socialmedia}</SloganDeLa1>
            </SloganDeLaFoireInternationParent>
        </WelcomeSection>
        {/* <DetailanChild /> */}
            <DetailanItem>
                <MapContainer center={[-18.95264, 47.51949]} zoom={13} scrollWheelZoom={true}>
                    <TileLayer
                        // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker
                        icon={icon}
                        position={[-18.95264, 47.51949]}
                    >
                        <Popup>
                            Forello Expo
                        </Popup>
                    </Marker>
                </MapContainer>
            </DetailanItem>
        </DetailanRoot>
    </>
  );
};

export default DetailAn;
