import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const BackgroundOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 70px;
`;

const HallImage = styled.img`
  width: auto;
  height: 100%;
`;

const Hall = ({ imageUrl, isVisible, onHide }) => {
    const handleImageClick = (event) => {
        // Prevent event propagation to the parent container
        event.stopPropagation();
    };
    return (
        <BackgroundOverlay
        initial={{ opacity: 0 }}
        animate={{ opacity: isVisible ? 1 : 0 }}
        transition={{ duration: 0.5 }}
        style={{ display: isVisible ? "flex" : "none" }}
        onClick={onHide} // Call onHide callback when clicked
        >
        <HallImage onClick={handleImageClick} src={imageUrl} alt="Hall Image" />
        </BackgroundOverlay>
    );
};

export default Hall;
